.wrap-search-main
	background-color: rgba(0, 0, 0, 0.9)
	padding: 10px 0px
	padding-bottom: 15px
	position: fixed
	width: 100%
	top: 0
	opacity: 0
	visibility: hidden
	transition: .4s all ease-in-out
	z-index: 99
	box-shadow: 1px 5px 15px rgba(0, 0, 0, 0.4)
	display: flex
	align-items: center
	height: 100%
	&.active
		opacity: 1
		visibility: visible
		.close
			top: 5px
			opacity: 1
		.searchbox
			button
				em
					transform: translateY(0px)
					opacity: 1
					transition-delay: .5s
			input
				transform: translateX(0px)
				opacity: 1
			&::before
				width: 100%
				opacity: 1
	.close
		position: absolute
		top: 0px
		right: 20px
		display: flex
		cursor: pointer
		opacity: 0
		transition: .3s all ease-in-out
		transition-delay: .4s
		em
			color: white
			font-size: 40px
	.searchbox
		display: flex
		align-items: center
		position: relative
		padding-bottom: 5px
		width: 100%
		[class*="Module"]
			width: 100%
		&.focus
			&::after
				opacity: 1
				width: 100%
		&.notFocus
			&::after
				opacity: 1
				right: 0
				left: auto
				width: 0%
		&::before
			content: ''
			width: 0%
			opacity: 1
			height: 1px
			position: absolute
			left: 0
			top: calc( 100% + 5px )
			background-color: #fff
			transition-delay: .1s
			transition: .75s all ease-in-out
			// transition-timing-function: ease-in-out
		&::after
			content: ''
			width: 0
			opacity: 0
			height: 1px
			position: absolute
			left: 0
			top: calc( 100% + 5px )
			background-color: $main
			transition-delay: .1s
			transition: .5s all ease-in-out
		input
			flex: 1
			border: 0
			background-color: transparent
			color: white
			transition: .6s all ease-in-out
			transition-delay: .2s
			transform: translateX(-30px)
			opacity: 0
			&::placeholder
				color: white
		button
			outline: none
			border: 0
			background-color: transparent
			display: flex
			padding: 0
			em
				color: white
				opacity: 0
				transform: translateY(-20px)
				transition: .3s all ease-in-out
