.section-home-4
	min-height: 548px
	background-position: fixed
	display: flex
	margin-top: 29px
	align-items: center
	justify-content: center
	@media (max-width: 575.98px)
		padding-top: r(80px)
		padding-bottom: r(40px)
	.title-main
		text-transform: uppercase
	.desc-main
		color: #ffffff
		font-size: 1rem
		font-weight: 400
		margin-top: 30px
		text-align: center
	.wrap-box-center
		max-width: 990px
		margin: 0 auto
	.wrap-box-circle
		display: flex
		align-items: center
		justify-content: space-between
		max-width: 909px
		width: 100%
		margin: 0 auto
		margin-top: 40px
		flex-wrap: wrap
		.circle-item
			display: flex
			justify-content: center
			flex-direction: column
			align-items: center
			flex: 1 0 auto
			margin-bottom: 30px
			.text
				color: white
				margin-top: r(30px)
				@media (max-width: 575.98px)
					margin-top: r(15px)
			.circle
				display: flex
				align-items: center
				justify-content: center
				width: 110px
				height: 110px
				border-radius: 50%
				border: 2px solid #ffffff
				transition: .3s all ease-in-out
				&:hover
					background-color: $main
					transform: scale(1.05)

