.zone-tab-1
	position: relative
	overflow-x: auto
	&::after
		content: ''
		position: absolute
		bottom: 2px
		left: 0
		width: 100%
		height: 1px
		background-color: $line
	ul
		+type-none
		display: flex
		align-items: center
		padding: 0 15px
		margin: 0 auto
		width: fit-content
		li
			display: flex
			align-items: center
			&+li
				&::before
					content: '|'
					padding: 0 r(40px)
					@media (max-width: 767.98px)
						padding: 0 r(15px)
			&.active
				a
					color: $main
					&::before
						width: 56px
			a
				color: #111111
				font-size: r(14px)
				font-weight: 400
				display: flex
				align-items: center
				height: 49px
				text-transform: uppercase
				color: #111111
				position: relative
				white-space: nowrap
				transition: .3s all  ease-in-out
				text-align: center
				padding-top: 3px
				@media (max-width: 575.98px)
					font-size: r(12px)
				&:hover
					color: $main
				&::before
					content: ''
					width: 0
					height: 3px
					position: absolute
					top: 91%
					z-index: 10
					left: 50%
					transform: translate(-50%)
					background-color: $main
					transition: .3s all  ease-in-out
