.page-news-list
	@media (max-width: 575.98px)
		margin-bottom: 20px
	.title-page
		margin-top: 25px
		margin-bottom: 29px
	.row
		@media (max-width: 1023.98px)
			margin-left: -5px
			margin-right: -5px
			[class*='col']
				padding-left: 5px
				padding-right: 5px
.item-news-post
	&.big
		position: relative
		margin-bottom: 30px
		@media (max-width: 575.98px)
			margin-bottom: 10px
		.img
			a
				display: block
				width: 100%
				height: 420px
				position: relative
				@media (max-width: 1023.98px)
					height: auto
				&::before
					content: ''
					background-image: linear-gradient(to top, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0.01) 99%, rgba(0, 0, 0, 0) 100%)
					position: absolute
					background-repeat: no-repeat
					top: 0
					left: 0
					width: 100%
					height: 100%
					z-index: 2
				img
					width: 100%
					height: 100%
					object-fit: cover
		.wrap-text
			position: absolute
			bottom: 0
			left: 0
			width: 100%
			z-index: 3
			padding: 0px 20px 38px 30px
			@media (max-width: 767.98px)
				padding: 0 15px 15px 15px
	&.small
		height: 420px
		margin-bottom: 30px
		@media (max-width: 1023.98px)
			height: auto
		@media (max-width: 575.98px)
			margin-bottom: 10px
		.title
			color: #111111
		.date
			color: #666666
		.content
			color: #111111
		.img
			a
				display: block
				width: 100%
				height: 220px
				@media (max-width: 1023.98px)
					height: auto
				img
					width: 100%
					height: 100%
					object-fit: cover
		.wrap-text
			height: 200px
			padding: 24px 35px 30px 29px
			background-color: $gray
			transition: .3s all ease-in-out
			@media (max-width: 1023.98px)
				height: 175px
			@media (max-width: 767.98px)
				padding: 15px
				height: 150px
			&:hover
				background-color: $main
				.date
					color: white
				.title
					color: white
				.content
					color: white
	.date
		color: #ffffff
		font-size: r(14px)
		font-weight: 400
		margin-bottom: 5px
		transition: .3s all ease-in-out
	.title
		color: #ffffff
		font-size: 1rem
		font-weight: 700
		margin-bottom: 8px
		display: block
		transition: .3s all ease-in-out
		+line(2)
		@media (max-width: 767.98px)
			font-size: r(14px)
		&:hover
			text-decoration: underline
	.content
		color: #ffffff
		transition: .3s all ease-in-out
		font-size: r(14px)
		+line(3)
