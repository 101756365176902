.btn
	cursor: pointer
	user-select: none
	&.btn-main
		display: flex
		padding: 0 r(22px)
		height: r(45px)
		border-radius: 23px
		background-color: $main
		align-items: center
		justify-content: center
		min-width: r(170px)
		transition: .3s all ease-in-out

		&.grey
			background-color: $gray
			span
				color: $text
			em
				color: #111111
		&:hover
			transform: translateY(-3px) scale(1.02) translateZ(0)
			box-shadow: 4px 4px 0px $green-hover
		span
			color: #ffffff
			font-size: r(14px)
			font-weight: 500
			margin-right: 6px
		em
			color: white
			font-size: 18px
	&.btn-sw-1
		width: 17px
		height: 31px
		top: 38% !important
	&.btn-sw-2
		filter: brightness(0) invert(1)
		width: 17px
		height: 31px
	&.btn-view-detail
		display: flex
		align-items: center
		&:hover
			span,em
				color: $main
		span
			color: #333333
			font-size: r(14px)
			font-weight: 500
		em
			color: #333333
			margin-left: 15px
	&.btn-contact
		width: 170px
		height: 45px
		display: flex
		align-items: center
		justify-content: center
		border-radius: 23px
		background-color: $main
		border: 2px solid transparent
		transition: .3s all ease-in-out
		&:hover
			border: 2px solid $main
			background-color: #fff
			box-shadow: 2px 2px 0px $main
			em,span
				color: $main
		em
			color: white
			margin-right: 15px
			font-size: 18px
			transition: .3s all ease-in-out
		span
			color: #ffffff
			font-size: r(14px)
			font-weight: 500
			transition: .3s all ease-in-out
.wrap-button-slide
	display: flex
	align-items: center
	justify-content: center
	.btn-prev,.btn-next
		position: absolute
		top: 50%
		transform: translateY(-50%)
		@media (max-width: 575.98px)
			display: none
	.btn-prev
		right: 100%
		margin-right: 30px
		@media (max-width: 1300px)
			margin-right: 10px
	.btn-next
		left: 100%
		margin-left: 30px
		@media (max-width: 1300px)
			margin-left: 10px
.wrap-slide-main
	position: relative
.btn-left
	display: flex
	justify-content: flex-start
