footer
	background-color: $main
	padding-top: 70px
	padding-bottom: 40px
	@media (max-width: 1023.98px)
		padding-top: r(50px)
		padding-bottom: r(30px)
	.title-footer
		color: #ffffff
		font-size: r(18px)
		font-weight: 700
		margin-bottom: 18px
		text-transform: uppercase
	.desc-footer
		opacity: 0.75
		color: #ffffff
		font-size: r(14px)
		font-weight: 300
		margin-bottom: 16px
	.col-lg-3
		@media (max-width: 1023.98px)
			margin-bottom: 30px
		&:first-child
			.title-footer
				margin-bottom: 25px
			.list-footer
				li
					font-weight: 300
	ul.list-footer
		+type-none()
		li
			color: white
			opacity: .75
			font-size: r(14px)
			&+li
				margin-top: 11px
			a
				color: white
				opacity: .75
				font-size: r(14px)
				padding-bottom: 5px
				font-weight: 300
				transition: .3s all ease-in-out !important
				+hover-underline(95%, white)
				&:hover
					color: white
					opacity: 1
	.wrap-list-social
		+type-none()
		display: flex
		align-items: center
		margin-top: r(50px)
		@media (max-width: 1023.98px)
			margin-top: r(30px)
		li
			&+li
				margin-left: 10px
			a
				width: 40px
				height: 40px
				border: 1px solid rgba(255, 255, 255, .75)
				display: flex
				align-items: center
				border-radius: 50%
				justify-content: center
				transition: .3s all ease-in-out
				&:hover
					background-color: #fff
					em
						color: $main
						opacity: 1
				em
					color: white
					opacity: .75
					transition: .3s all ease-in-out
	.copyright-box
		padding-top: r(80px)
		padding-bottom: r(40px)
		@media (max-width: 1023.98px)
			padding-top: r(10px)
			padding-bottom: r(30px)
		.col-lg-6
			display: flex
			align-items: center
			[class*='Module']
				width: 100%
		.copyright
			display: flex
			align-items: center
			.text
				margin-left: 12px
				opacity: 0.5
				color: #ffffff
				font-size: r(14px)
				font-weight: 400
		.list-footer
			display: flex
			align-items: center
			margin-left: auto
			width: fit-content
			@media (max-width: 1023.98px)
				margin-top: 15px
			li
				margin-top: 0
				&+li
					text-align: center
					&::before
						content: '|'
						padding: 0 4px

