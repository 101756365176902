.page-doi-tac
	@media (min-width: 1024px)
		padding-bottom: r(80px)
		padding-top: r(80px)
	.wrap-flex
		display: flex
		flex-wrap: wrap
		.wrap-left-dt
			flex: 0 0 58.3739%
			max-width: 58.3739%
			@media (max-width: 1023.98px)
				flex: 0 0 100%
				max-width: 100%
		.wrap-right-dt
			flex: 0 0 41.6261%
			max-width: 41.6261%
			padding-left: 90px
			@media (max-width: 1279.98px)
				padding-left: 30px
			@media (max-width: 1023.98px)
				flex: 0 0 100%
				max-width: 100%
				padding-left: 0
	.wrap-title-arrow
		display: flex
		align-items: center
		justify-content: space-between
		margin-bottom: 28px
		.wrap-button
			display: flex
			align-items: center
			.btn-left
				margin-right: 10px
	.wrap-img-dt
		border: 1px solid #eeeeee
		.swiper-slide-thumb-active
			.img-dt
				transition: .3s all ease-in-out
				box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.08)
				z-index: 10
				position: relative
		.img-dt
			border-right: 1px solid #eeeeee
			border-bottom: 1px solid #eeeeee
			background-color: #ffffff
			display: flex
			align-items: center
			cursor: pointer
			height: 114px
			justify-content: center
			transition: .3s all ease-in-out
			padding: 5px
			img
				max-width: 100%
				max-height: 100%
				object-fit: contain
			&:hover
				transition: .3s all ease-in-out
				box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.08)
				z-index: 10
				position: relative
	.item-info-dt
		background-image: url('../img/bgdt.png')
		background-size: cover
		width: 100%
		height: 403px
		padding: 60px
		margin-top: 12px
		@media (max-width: 1279.98px)
			padding: r(30px)
		h2
			color: $black
			font-size: r(36px)
			font-weight: 600
		p
			color: $black
			font-size: 1rem
			margin-top: 20px
			font-weight: 400
	.title-main
		text-transform: uppercase
