header
	position: fixed
	top: 0
	left: 0
	width: 100%
	background-color: #fff
	z-index: 99
	border-bottom: 1px solid #ebebeb
	nav
		position: relative
		background-color: #fff
		z-index: 100
		.header-search
			position: relative
			cursor: pointer
			max-width: 300px
			width: 100%
			height: 35px
			border-radius: 18px
			border: 1px solid #ebebeb
			background-color: #f8f8f8
			display: flex
			align-items: center
			justify-content: center
			padding: 0 45px 0px 15px
			transition: .3s all  ease-in-out
			.Module,.searchbox
				width: 100%
			&.active
				border: 1px solid $main
				input
					font-size: r(14px)
					pointer-events: auto
				button
					left: 92%
					pointer-events: auto
			@media (max-width: 1024.98px)
				max-width: 35px
				height: 35px
			button
				border: 0
				outline: none
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
				transition: .3s all  ease-in-out
				background-color: transparent
				pointer-events: none
			em
				color: $main
				font-size: 20px
			input
				border: 0
				width: 100%
				font-size: 0
				background-color: #f8f8f8
				pointer-events: none
		.header-top
			display: flex
			align-items: center
			justify-content: space-between
			padding-top: 17px
			@media (max-width: 1024.98px)
				display: none
			.header-location-phone
				display: flex
				.phone
					margin-right: 27px
					margin-top: 4px
					a
						display: flex
						align-items: center
					span
						color: $main
						font-size: 1rem
						font-weight: 600
						text-transform: uppercase
					em
						color: $main
						margin-right: 3px
						font-size: 24px
				.language
					display: flex
					align-items: center
					a
						width: 21px
						height: 21px
						display: block
						border-radius: 50%
						&+a
							margin-left: 10px
						img
							width: 100%
							height: 100%
		.header-bottom
			display: flex
			justify-content: center
			height: 66px
			padding-bottom: 4px
			.logo
				flex: 0 0 202px
				display: flex
				align-items: center
				justify-content: center
				position: relative
				@media (max-width: 1024.98px)
					[class*="Module"]
						height: 100%
				.img
					position: absolute
					top: -35px
					@media (max-width: 1024.98px)
						top: 0
						height: 100%
						padding: 5px 0px
						img
							height: 100%
							object-fit: contain
							position: relative
					// transform: translateY(-37px)
			.list-menu-left,.list-menu-right
				flex: 1
				display: flex
				align-items: center
				justify-content: space-between
				max-width: 100%
				[class*="Module"]
					width: 100%
			.list-menu-right
				display: flex
				justify-content: flex-end
			ul
				+type-none()
				flex: 1
				max-width: 100%
				display: flex
				align-items: center
				justify-content: space-between
				@media (max-width: 1023.98px)
					display: none
				li
					&.active
						a
							color: $main
							&::before
								width: 100%
					a
						font-size: r(15px)
						font-weight: 600
						text-transform: uppercase
						position: relative
						padding-bottom: 5px
						transition: .3s all ease-in-out
						&:hover
							color: $main
							&::before
								width: 100%
						&::before
							content: ''
							position: absolute
							top: 100%
							height: 1px
							background-color: $main
							width: 0%
							transition: .3s all ease-in-out
							left: 50%
							transform: translateX(-50%)
	.header-mobile
		position: fixed
		top: 63px
		left: 0
		width: 300px
		background-color: white
		border-top: 1px solid #f1f1f1
		height: 100vh
		z-index: 99
		transition: .3s all ease-in-out
		transform: translateX(-100%)
		opacity: 0
		&.active
			transform: translateX(0)
			opacity: 1
		ul
			+type-none()
			li
				&.active
					a
						background-color: $main
						color: white
				a
					display: block
					padding: 14px 15px
					border-top: 1px solid #f1f1f1
					font-size: r(18px)
		.language
			height: 50px
			display: flex
			align-items: center
			padding-left: 15px
			a + a
				margin-left: 10px




