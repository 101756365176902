::-webkit-scrollbar-track
	box-shadow: inset 0 0 6px rgba(255, 255, 255, 0)
	background-color: #cccccc
	border-radius: 20px
::-webkit-scrollbar
	width: 4px
	height: 4px
	cursor: pointer
::-webkit-scrollbar-thumb
	border-radius: 20px
	box-shadow: inset 0 0 6px rgba(255, 255, 255, 0)
	background-color: $main
	cursor: pointer
::selection
	background: $main
	color: white
main
	padding-top: 119px
	@media (max-width: 1023.98px)
		padding-top: 64px
html
	font-family: 'Montserrat', sans-serif;
	line-height: 1.5
	-webkit-text-size-adjust: 100%
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0)
body
	font-family: 'Montserrat', sans-serif;
	font-weight: 400
	line-height: 1.5
	background-color: $body
	color: $text
	margin-top: 0
	margin-bottom: 0
body, html
	font-size: 13px
	@media(min-width: 1024px)
		font-size: 14px
	@media(min-width: 1280px)
		font-size: 15px
	@media(min-width: 1600px)
		font-size: 16px
*
	box-sizing: border-box
	&::before, &::after
		box-sizing: border-box

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section
	display: block

[tabindex="-1"]:focus
	outline: 0 !important

hr
	box-sizing: content-box
	height: 0
	overflow: visible

h1, h2, h3, h4, h5, h6
	margin-top: 0
	margin-bottom: 0

p
	margin-top: 0
	margin-bottom: 0

abbr[title], abbr[data-original-title]
	text-decoration: underline
	-webkit-text-decoration: underline dotted
	text-decoration: underline dotted
	cursor: help
	border-bottom: 0
	-webkit-text-decoration-skip-ink: none
	text-decoration-skip-ink: none

address
	margin-bottom: 0
	font-style: normal
	line-height: inherit

ol, ul, dl
	margin-top: 0
	margin-bottom: 0

dt
	font-weight: 700

dd
	margin-bottom: 0
	margin-left: 0

blockquote
	margin: 0

b, strong
	font-weight: bolder

small
	font-size: 80%

sub, sup
	position: relative
	font-size: 75%
	line-height: 0
	vertical-align: baseline

sub
	bottom: -.25em

sup
	top: -.5em

a
	text-decoration: none
	background-color: transparent
	font-size: inherit
	font-weight: inherit
	color: inherit


a:not([href]):not([tabindex])
	color: inherit
	text-decoration: none

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus
	color: inherit
	text-decoration: none

a:not([href]):not([tabindex]):focus
	outline: 0

pre, code, kbd, samp
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
	font-size: 1em

pre
	margin-top: 0
	margin-bottom: 0
	overflow: auto

figure
	margin: 0

img
	vertical-align: middle
	border-style: none

svg
	overflow: hidden
	vertical-align: middle

table
	border-collapse: collapse

caption
	padding-top: 0
	padding-bottom: 0
	color: #6c757d
	text-align: left
	caption-side: bottom

th
	text-align: inherit

label
	display: inline-block
	margin-bottom: 0

button
	border-radius: 0

button:focus
	outline: none
	outline: 5px auto -webkit-focus-ring-color

input, button, select, optgroup, textarea
	margin: 0
	font-family: inherit
	font-size: inherit
	line-height: inherit

button, input
	overflow: visible

button, select
	text-transform: none

select
	word-wrap: normal

button, [type="button"], [type="reset"], [type="submit"]
	-webkit-appearance: button

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled)
	cursor: pointer

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner
	padding: 0
	border-style: none

input[type="radio"], input[type="checkbox"]
	box-sizing: border-box
	padding: 0

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"]
	-webkit-appearance: listbox

textarea
	overflow: auto
	resize: vertical

fieldset
	min-width: 0
	padding: 0
	margin: 0
	border: 0

legend
	display: block
	width: 100%
	max-width: 100%
	padding: 0
	margin-bottom: 0
	font-size: 1.5rem
	line-height: inherit
	color: inherit
	white-space: normal

progress
	vertical-align: baseline

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button
	height: auto

[type="search"]
	outline-offset: -2px
	-webkit-appearance: none

[type="search"]::-webkit-search-decoration
	-webkit-appearance: none

::-webkit-file-upload-button
	font: inherit
	-webkit-appearance: button

output
	display: inline-block

summary
	display: list-item
	cursor: pointer

template
	display: none

[hidden], .d-none, .hidden
	display: none !important

input, button, a, .button-prev, .button-next, textarea, select
	&:focus
		outline: none

img
	max-width: 100%
	height: auto
