.section-home-2
	margin-bottom: 150px
	margin-top: 15px
	.banner-home-img
		position: relative
		>.img
			height: 600px
			@media (max-width: 1279.98px)
				height: 510px
			@media (max-width: 1023.98px)
				height: 500px
			@media (max-width: 767.98px)
				height: 400px
			@media (max-width: 575.98px)
				height: 427px
			img
				height: 100%
				width: 100%
				object-fit: cover
				object-position: center
	.container
		position: absolute
		left: 50%
		transform: translateX(-50%)
		top: 19.5%
		display: flex
		justify-content: flex-end
		@media (max-width: 767.98px)
			top: 10.5%
	.text
		display: flex
		align-items: flex-start
		max-width: 495px
		flex-direction: column
		@media (max-width: 1023.98px)
			max-width: 100%
		*
			text-align: left
		.home2-title-sub
			color: #ffffff
			font-size: r(18px)
			font-weight: 600
			text-transform: uppercase
			margin-bottom: 5px
			@media (max-width: 1023.98px)
				margin-bottom: 10px
		.home2-title
			color: #ffffff
			font-size: r(48px)
			line-height: 1.145
			font-weight: 400
			margin-bottom: 40px
			@media (max-width: 767.98px)
				margin-bottom: 20px
				font-size: r(40px)
		.btn-main
			span
				margin-right: 15px
	.wrap
		display: flex
		flex-direction: column
		max-width: 705px
		width: 100%
		align-items: flex-end
	.wrap-slide-product
		max-width: 705px
		width: 100%
		margin-top: 86px
		position: relative
		padding-left: 30px
		padding-right: 75px
		@media (max-width: 1023.98px)
			padding-left: 0px
		@media (max-width: 767.98px)
			margin-top: 60px
	.product-image
		display: block
		width: 100%
		&:hover
			.title
				color: $main
		.img
			height: 200px
			display: flex
			align-items: center
			justify-content: center
			img
				max-width: 100%
				max-height: 100%
				object-fit: contain
		.title
			color: #333333
			font-size: r(14px)
			font-weight: 600
			text-align: center
			transition: .3s all ease-in-out
			margin-top: r(20px)
			position: relative
			&:hover
				color: $main
	.btn-next-slide
		width: 59px
		height: 59px
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.05)
		background-color: #ffffff
		display: flex
		align-items: center
		border-radius: 50%
		justify-content: center
		position: absolute
		top: 46%
		transform: translateY(-50%)
		right: 0
		filter: none !important
		transition: .3s all ease-in-out
		&:hover
			box-shadow: 2px 2px $green-hover
			background-color: $main
			img
				filter: brightness(0) invert(1)
