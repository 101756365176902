.banner-home
	&:hover
		.wrap-button-slide
			.btn
				opacity: 1
	.box-banner
		position: relative
		@media (min-width: 1600px)
			height: 550px
		@media (max-width: 1023.98px)
			height: 40vh
		.img
			height: 100%
			&::before
				content: ''
				background-color: rgba(0,0,0,0.35)
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
			img
				height: 100%
				width: 100%
				object-fit: cover
	.box-text
		position: absolute
		top: 34%
		left: 50%
		transform: translate(-50%)
		@media (max-width: 1350px)
			top: 15%
		@media screen and ( max-width: 768px )
			left: 50%
			top: 20%
		.wrap-text
			display: flex
			flex-direction: column
			align-items: flex-start
			max-width: 550px
			text-align: left
		.title-sub
			color: #ffffff
			font-size: r(20px)
			font-weight: 500
			margin-bottom: r(15px)
			text-transform: uppercase
			@media (max-width: 1279.98px)
				font-size: r(16px)
		.title-white
			text-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
			color: #ffffff
			font-size: r(34px)
			line-height: 1.2
			font-weight: 600
			text-transform: uppercase
			margin-bottom: r(30px)
			@media (max-width: 1279.98px)
				font-size: r(30px)
				margin-bottom: r(25px)
	.swiper-pagination
		text-align: left
		left: 50% !important
		bottom: 32px
		transform: translateX(-50%)
		&-bullet
			width: 10px
			margin: 0 5px
			height: 10px
			border: 2px solid #ffffff
			opacity: 1
			background-color: transparent
			&-active
				background-color: #fff
	.swiper-slide
		&.swiper-slide-active
			.wrap-text
				opacity: 1
				transform: translateY(0)
		.wrap-text
			will-change: transform, opacity
			transform: translateY(50%)
			opacity: 0
			transition: transform 0.5s 0.25s, opacity 0.5s 0.25s
	.wrap-button-slide
		.btn-prev,.btn-next
			z-index: 10
			@media (min-width: 1280px)
				opacity: 0
				transition: .3s all ease-in-out
		.btn-prev
			right: auto
			left: 3vw
		.btn-next
			left: auto
			right: 3vw
