.page-lien-he
	background-color: $gray
	padding-top: r(24px)
	.title-page
		margin-bottom: 19px
	.wrap-box-flex
		max-width: 936px
		margin: 0 auto
		.swiper-container
			padding: 10px
			@media (max-width: 575.98px)
				padding: 0
		.row
			margin-left: -10px
			margin-right: -10px
			[class*='col']
				padding-left: 10px
				padding-right: 10px
	.item-box-lh
		height: 175px
		border: 1px solid #eeeeee
		background-color: #ffffff
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		padding: 15px 25px 30px 25px
		transition: .3s all ease-in-out
		&:hover
			border: 1px solid $main
			box-shadow: 4px 4px $main
		.icon
			width: 45px
			height: 45px
			background-color: $main
			background-image: linear-gradient(-145deg, #2ce718 0%, $main 100%)
			display: flex
			border-radius: 50%
			align-items: center
			justify-content: center
			margin-bottom: 15px
		.wrap-text
			text-align: center
			@media (min-width: 1024px)
				padding: 0 r(20px)
			strong
				color: $black
				font-size: 12px
				font-weight: 800
				text-transform: uppercase
				margin-bottom: 5px
				display: block
			p
				color: $black
				font-size: 14px
	.wrap-social
		+type-none()
		display: flex
		align-items: center
		margin-top: 20px
		li
			&+li
				margin-left: 10px
			a
				width: 40px
				height: 40px
				border: 1px solid $line
				display: flex
				align-items: center
				justify-content: center
				border-radius: 50%
				transition: .3s all ease-in-out
				&:hover
					background-color: $main
					border: 1px solid $main
					box-shadow: 2px 2px 0px $green-hover
					em
						color: white
				em
					color: #b0b0b0
					transition: .3s all ease-in-out
					font-size: r(18px)
					font-weight: 400
.wrap-map-form
	background-color: $gray
	padding-bottom: r(80px)
	padding-top: r(40px)
	span.fa-exclamation-triangle
		color: red
		position: absolute
		top: 19px
		right: 15px
		font-weight: 400!important
		font-style: italic
		font-size: .9rem
		animation: AlertError 0.4s 1 linear!important
	.wrap-flex-form
		display: flex
		flex-wrap: wrap
		background-color: #fff
		padding: 10px
		box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.05)
	.iframe-map
		flex: 0 0 50.4065%
		height: 630px
		@media (max-width: 1023.98px)
			flex: 0 0 100%
			max-width: 100%
			height: 50vh
		@media (max-width: 767.98px)
			height: 30vh
	textarea
		width: 100%
		height: 100px
		border: 1px solid #eeeeee rgba(#eeeeee, .75)
		background-color: #ffffff
		padding-left: 20px
		color: $black
		padding-top: 15px
		&::placeholder
			font-size: 14px
			color: #999999
	.wrap-form-main
		padding-top: 40px
		flex: 0 0 49.5935%
		padding-left: 70px
		padding-right: 70px
		@media (max-width: 1023.98px)
			flex: 0 0 100%
			max-width: 100%
			padding: r(40px) r(35px) r(70px) r(35px)
		@media (max-width: 575.98px)
			padding: r(40px) r(15px) r(70px) r(15px)
		.title
			color: $black
			font-size: r(14px)
			font-weight: 400
			margin-bottom: 20px
			line-height: 1.7142
		.form-group
			margin-bottom: 19px
			position: relative
			@extend .hover-input
			input[type="text"]
				width: 100%
				height: 50px
				border: 1px solid rgba(#eeeeee, .75)
				background-color: #ffffff
				font-size: 14px
				color: $black
				padding-left: 20px
				&::placeholder
					font-size: 14px
					color: #999999
	.frm-btnwrap
		display: flex
		justify-content: flex-start
		.frm-btn
			position: relative
			&:hover
				input
					background-color: #fff
					border: 1px solid $main
					color: $main
					box-shadow: 4px 4px $main
				&::before
					color: $main
			&::before
				content: "\EA6E"
				font-family: remixicon!important
				color: #ffffff
				font-size: r(20px)
				position: absolute
				top: 50%
				right: 20px
				top: 50%
				transform: translateY(-50%)
				transition: .3s all ease-in-out
			input
				width: 170px
				height: 45px
				border-radius: 23px
				background-color: $main
				box-shadow: none
				border: 0
				text-align: center
				color: #ffffff
				font-size: 14px
				transition: .3s all ease-in-out


