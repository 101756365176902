.section-home-1
	padding-top: r(75px)
	padding-bottom: r(40px)
	@media (max-width: 767.98px)
		padding-top: r(30px)
		padding-bottom: r(30px)
	.title-main
		margin-bottom: 45px
	.wrap-slide-main
		padding: 0 15px
	.circle-product
		&:hover
			.img
				&::before
					opacity: 1
				em
					transform: translate(-50%,-50%) scale(1)
		.title-product
			color: #333333
			font-size: r(14px)
			font-weight: 600
			margin-top: 30px
			text-align: center
			transition: .3s all  ease-in-out
			&:hover
				color: $main
		.img
			background: rgba(15, 28, 63, 0.125)
			border-radius: 50%
			display: block
			height: 0
			overflow: hidden
			padding-top: 100%
			position: relative
			width: 100%
			border: 1px solid #ebebeb
			img
				height: auto
				left: 0
				position: absolute
				top: 0
				width: 100%
			&::before
				content: ''
				position: absolute
				top: 0
				left: 0
				opacity: 0
				width: 100%
				z-index: 1
				height: 100%
				transition: .3s all  ease-in-out
				background-color: rgba(0, 0, 0, 0.3)
			em
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%) scale(0)
				transition: .3s all  ease-in-out
				color: #ffffff
				font-size: r(20px)
				font-weight: 400
				z-index: 3
