.category-page-gt
	position: fixed
	top: 100px
	left: -100%
	opacity: 0
	visibility: hidden
	transition: all 600ms cubic-bezier(0.825, 0.09, 0.085, 1.01)
	&.show-p
		left: 0
		opacity: 1
		visibility: visible
		z-index: 10
		width: 300px
	&.active
		.title-header
			width: 100%
			opacity: 1
			visibility: visible
		.list-menu-page
			width: 100%
			padding-left: 10px
			opacity: 1
			visibility: visible
			max-height: 100%
	.title-header
		display: none
		align-items: center
		background-color: $main
		padding: 7px 10px
		width: 43px
		cursor: pointer
		overflow: hidden
		transition: .3s all ease-in-out
		display: flex
		span
			font-weight: bold
			color: white
			white-space: nowrap
		em
			color: white
			margin-right: 10px
	.list-menu-page
		background-color: #fff
		padding-top: 5px
		padding-bottom: 5px
		list-style: none
		border-radius: 0px 0px 5px 5px
		box-shadow: 2px 3px 10px #0000001c
		overflow: hidden
		width: 0
		max-height: 0
		padding-left: 0
		opacity: 0
		visibility: hidden
		transition: .3s all ease-in-out
		li
			display: flex
			align-items: center
			&.active
				a
					color: $main
			a
				white-space: nowrap
				&.active
					color: $main
			&::before
				content: 'keyboard_arrow_right'
				font-family: 'Material Icons'
				font-size: 18px
				color: $main
				margin-right: 5px