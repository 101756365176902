.section-home-7
	padding-bottom: r(40px)
	.title-main
		margin-bottom: 30px
		text-transform: uppercase
	.row
		@media (max-width: 1023.98px)
			flex-direction: column-reverse
	.home-7-post-list
		padding-right: r(45px)
		@media (max-width: 1199.98px)
			padding-right: 0px
		.home-7-post-item
			display: flex
			transition: .3s all ease-in-out
			&:hover
				box-shadow: 2px 2px 5px rgba($main, .3)
			&+.home-7-post-item
				margin-top: 10px
			.img
				flex: 0 0 210px
				max-width: 210px
				height: 140px
				@media (max-width: 575.98px)
					flex: 0 0 155px
					max-width: 155px
					height: 110px
				a
					display: block
					width: 100%
					height: 100%
					img
						width: 100%
						height: 100%
						object-fit: cover
			.content
				height: 140px
				background-color: $gray
				display: flex
				flex-direction: column
				flex: 1
				max-width: 100%
				align-items: flex-start
				padding-left: 30px
				justify-content: center
				@media (max-width: 575.98px)
					height: 110px
				.title
					color: $main
					font-size: r(20px)
					font-weight: 700
					margin-bottom: 11px
					text-transform: uppercase
	.wrap-video-main
		position: relative
		display: block
		width: 100%
		transition: .3s all ease-in-out
		@media (max-width: 1023.98px)
			margin-bottom: 30px
		&:hover
			box-shadow: 8px 8px $main
		.img
			+img-ratio(355/559)
			padding-top: 59.50626%
			img
		.button-play
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			width: 56px
			height: 56px
			background-color: $main
			opacity: 0.9
			border-radius: 50%
			display: flex
			align-items: center
			justify-content: center
			transition: .3s all ease-in-out
			&:hover
				box-shadow: 4px 4px $green-hover

