.zoom-img
    overflow: hidden
    &:hover
        img
            transform: scale(1.05) translateZ(0)
    img
        transition: .3s all ease-in-out
@keyframes fade-in
    from
        opacity: 0
        transform: translateY(40px)
    to
        opacity: 1
        transform: translateY(0px)
@keyframes fade-in2
    from
        opacity: 0
        transform: translateX(-50%) translateY(20px)
    to
        opacity: 1
        transform: translateX(-50%) translateY(0px)
@keyframes moveInTop
    0%
        opacity: 0
        transform: translateY(45px)
        filter: blur(5)
    100%
        opacity: 1
        transform: translateY(0)
        filter: blur(0)
@keyframes blurText
    0%
        filter: blur(2px)
    100%
        filter: blur(2px)

@keyframes loading
    0%
        transform: rotate(0)

    100%
        transform: rotate(360deg)
@keyframes loading1-circle
    0%
        stroke-dashoffset: 0

    100%
        stroke-dashoffset: -600
#load
    width: 50px
    display: inline-block
    animation: loading 2s linear infinite
#loading-inner
    stroke-dashoffset: 1
    stroke-dasharray: 300
    stroke-width: 10
    stroke-miterlimit: 10
    stroke-linecap: round
    animation: loading1-circle 2s linear infinite
    stroke: $main
    fill: transparent
@keyframes AlertError
    0%
        transform: translateX(30px)
    20%
        transform: translateX(-30px)
    40%
        transform: translateX(15px)
    60%
        transform: translateX(-15px)
    80%
        transform: translateX(8px)
    100%
        transform: translateX(0px)
.shine
    position: relative
    overflow: hidden
    &::before
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%)
        content: ""
        display: block
        height: 100%
        left: -75%
        position: absolute
        top: 0
        transform: skewX(-25deg)
        width: 50%
        z-index: 2
    &:hover
        &::before
            -webkit-animation: shine 0.85s
            animation: shine 0.85s
@-webkit-keyframes shine
    100%
    left: 125%
@keyframes shine
    100%
        left: 125%