.number-tab-wrap
	@media (max-width: 768.67px)
		display: flex
		.tabslet-tab
			flex: 1
		.icon-next
			flex: 0 0 40px
			display: flex
			align-items: center
			justify-content: center
			margin-left: 15px
			border: 1px solid $main
			.next
				margin-top: 5px
			em
				color: $main
