.wrap-lazy-slide
	&.active
		.swiper-slide
			opacity: 1
			height: auto
	.swiper-slide
		opacity: 0
		height: 100px
		transition: .3s all ease-in-out
		@for $i from 1 through 5
			&:nth-of-type(#{$i})
				transition-delay: .05 + $i * 0.1 +s
.disable
	overflow: hidden
.hambuger
	height: 19px
	align-items: center
	justify-content: center
	width: 25px
	position: relative
	transition: .3s all ease-in-out
	z-index: 3
	display: none
	flex: 0 0 25px
	cursor: pointer
	@media (max-width: 1024.98px)
		display: flex
	&.active
		span
			&:first-child
				top: 8px
				left: 50%
				width: 0px
			&:nth-child(2)
				transform: rotate(45deg)
				background: $main
			&:nth-child(3)
				transform: rotate(-45deg)
				background: $main
			&:nth-child(4)
				top: 8px
				left: 50%
				width: 0px
	span
		display: block
		position: absolute
		left: 0
		transform: rotate(0deg)
		opacity: 1
		transition: .5s ease-in-out
		background: $main
		width: 100%
		height: 4px
		border-radius: 20px
		&:first-child
			top: 0
		&:nth-child(2), &:nth-child(3)
			top: 8px
		&:nth-child(4)
			top: 16px
.overlay
	background-color: rgba(0, 0, 0, 0.5)
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: 99
	transition: .3s all ease-in-out
	pointer-events: none
	opacity: 0
	&.active
		opacity: 1
		pointer-events: auto
.pagination
	display: flex
	align-items: center
	justify-content: center
	+type-none()
	margin-bottom: r(80px)
	@media (max-width: 1023.98px)
		margin-bottom: r(30px)
	li
		&.active
			a,span
				background-color: $main
				color: white
		&+li
			margin-left: 10px
		a,span
			display: flex
			align-items: center
			justify-content: center
			width: 40px
			height: 40px
			border-radius: 50%
			color: $black
			font-size: 1rem
			font-weight: 700
			transition: .3s all ease-in-out
			&:hover
				background-color: $main
				color: white
.breadcrumb-grey
	.global-breadcrumb
		background-color: $gray
#ctl00_divAltContent2
	display: none
.swiper-pagination-1
	position: static !important
	margin-top: 30px
	@media (min-width: 1280px)
		display: none
	.swiper-pagination-bullet
		width: 10px
		height: 10px
		&.swiper-pagination-bullet-active
			background-color: $main !important
.fancybox-button[disabled], .fancybox-button[disabled]:hover
	display: none
.search-product-wrapper
	.wrap-product-list
		margin: r(30px) 0px
		.row
			row-gap: 30px
.product-detail-page
	.pagination
		display: none