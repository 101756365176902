.signup-page
	background-color: #fff
	padding: 60px 0px
	.signup-panel
		margin: 0 auto
		box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06)
		padding: 15px
		border-radius: 20px
		max-width: 450px
		width: 100%
	.col-xs-12
		flex: 0 0 100% !important
		max-width: 100%!important
	.headtitle
		font-size: r(36px)
		color: $main
		margin-bottom: 15px
		text-align: center
	label
		font-size: r(18px)
	input[type="text"], input[type="password"]
		padding: 5px !important
		height: 35px !important
		border: 0 !important
		outline: 0 !important
		width: 100% !important
		border-bottom: 1px solid #eaeaea !important
		margin-bottom: 5px !important
	select
		padding: 5px !important
		height: 35px !important
		border: 0 !important
		outline: 0 !important
		width: 100% !important
		border-bottom: 1px solid #eaeaea !important
		margin-bottom: 5px !important
	.form-group
		margin-bottom: 15px
	#ctl00_mainContent_RegisterUser___CustomNav0_StepNextButtonButton
		width: 100%
		background-color: $main
		color: white
		margin-top: 10px
		outline: 0
		border: 0
		padding: 5px 0px
