$main: #14713d
$green: #43a141
$main-hover: #ddd
$body: white
$text: #333333
$black: #111111
$gray: #f8f8f8
$gray-2: #666666
$green-hover: #0a552c
$line: #e1e1e1
