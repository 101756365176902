.section-product-main
	padding-bottom: r(75px)
	@media (max-width: 1023.98px)
		padding-bottom: r(30px)
	.wrap-title-all
		margin-bottom: 35px
	.row
		margin-left: -13.5px
		margin-right: -13.5px
	.project-img
		position: relative
		display: block
		cursor: pointer
		height: 100%
		width: 100%
		img
			width: 100%
			object-fit: cover
			height: 100%
	.wrap-left-img
		padding: 0 13.5px
		flex: 0 0 82.0206%
		max-width: 82.0206%
		height: 608px
		@media (max-width: 1279.98px)
			height: 495px
		@media (max-width: 1023.98px)
			height: 355px
			flex: 0 0 75%
			max-width: 75%
		@media (max-width: 767.98px)
			flex: 0 0 100%
			max-width: 100%
		.swiper-container
			height: 100%
		.swiper-wrapper
			height: 100%
		.img
			height: calc( 100% - 43px )
			img
				height: 100%
		.title
			color: #333333
			font-size: r(18px)
			margin-top: 15px
			padding-left: 4px
			font-weight: 600
			transition: .3s all ease-in-out
			position: relative
			display: inline-block
			&::before
				content: ''
				background-color: #f0f0f0
				z-index: -1
				position: absolute
				height: 50%
				width: 0%
				transform: translate(-50%) scale(0.8)
				left: 50%
				bottom: 2px
				transition: .3s all ease-in-out
			&:hover
				color: $main
				&::before
					width: 100%
					transform: translate(-50%) scale(1.05)
	.wrap-right-img
		position: relative
		padding: 0 13.5px
		flex: 0 0 17.9794%
		max-width: 17.9794%
		height: 565px
		max-height: 565px
		@media (max-width: 1279.98px)
			height: 452px
		@media (max-width: 1023.98px)
			height: 312px
			flex: 0 0 25%
			max-width: 25%
		@media (max-width: 767.98px)
			flex: 0 0 100%
			max-width: 100%
			height: auto
			margin-top: 15px
		&::before
			content: ''
			width: 30px
			height: 124px
			background-color: $green
			position: absolute
			top: 65px
			left: -14.5px
			@media (max-width: 767.98px)
				display: none
		.wrap-button-slide
			font-size: r(30px)
			@media (max-width: 1023.98px)
				display: none
			.button-prev
				position: absolute
				bottom: 99%
				user-select: none
				img
					transition: .3s all ease-in-out
				&:hover
					img
						transform: scale(1.1)
			.button-next
				position: absolute
				user-select: none
				top: 99%
				img
					transition: .3s all ease-in-out
				&:hover
					img
						transform: scale(1.05)
		.swiper-container
			height: 100%
		.swiper-wrapper
			height: 100%
			.swiper-slide
				.project-img
					overflow: hidden
				&:hover
					img
						filter: grayscale(0%)
				img
					filter: grayscale(100%)
					transition: .3s all ease-in-out
				&.swiper-slide-thumb-active
					img
						filter: grayscale(0%)


