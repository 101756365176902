.title-main
	color: $main
	font-size: r(30px)
	font-weight: 600
	@media (max-width: 575.98px)
		font-size: 1.8rem
	&.white
		color: white
	&.black
		color: #111111
.wrap-title-all
	display: flex
	align-items: baseline
	justify-content: space-between
	.title-main
	.all
		display: flex
		align-items: center
		color: #666666
		font-size: r(14px)
		font-weight: 500
		&:hover
			color: $main
			em
				color: $main
		em
			color: #666666
			font-size: 20px
.title-page
	color: $main
	font-size: r(30px)
	font-weight: 600
	text-align: center
	text-transform: uppercase
	margin-bottom: r(40px)
