.item-product-main
	.img
		// background-image: url('../img/product/background-product.png')
		// background-size: cover
		// background-repeat: no-repeat
		// background-size: 100% 100%
		// object-position: bottom
		background-color: white
		box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.08)
		a
			width: 100%
			display: flex
			height: 285px
			align-items: center
			padding: 30px
			justify-content: center
			@media (max-width: 1279.98px)
				height: 230px
			@media (max-width: 767.98px)
				padding: 15px
				height: 180px
			&:hover
				img
					transform: scale(1.05)
			img
				max-width: 100%
				max-height: 100%
				object-fit: contain
				transition: .3s all ease-in-out
	.content
		.product-title-cate
			display: block
			margin-top: 8px
			margin-bottom: 5px
			span
				color: $main
				font-size: r(12px)
				font-weight: 500
				text-transform: uppercase
				position: relative
				&:hover
					&::before
						width: 100%
				&::before
					content: ''
					position: absolute
					top: 105%
					left: 0
					height: 1px
					width: 0%
					transition: .3s all ease-in-out
					background-color: $main
		.product-title-main
			color: #333333
			font-size: r(18px)
			font-weight: 600
			transition: .3s all ease-in-out
			@media (max-width: 575.98px)
				font-size: r(16px)
			&:hover
				color: $main
		.btn-left
			display: flex
			margin-top: 12px
			justify-content: flex-start
	.btn-main
		span
			transition: .3s all ease-in-out
		em
			transition: .3s all ease-in-out
		&:hover
			background-color: $main
			span
				color: white
			em
				color: white
.swiper-button-disabled
	opacity: .5 !important
.item-cong-trinh
	margin-bottom: r(40px)
	.img
		transition: .3s all ease-in-out
		@media screen and ( min-width: 1025px )
			&:hover
				box-shadow: 8px 8px $main
		a
			+img-ratio(220/390)
	.title
		margin: r(15px) 0px
		display: block
.product-list-main
	@media (max-width: 575.98px)
		.row
			margin-left: -5px
			margin-right: -5px
			[class*="col"]
				padding-left: 5px
				padding-right: 5px
