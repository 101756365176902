.page-service-detail
	padding-top: r(10px)
	padding-bottom: r(10px)
	@media (min-width: 1200px)
		padding-top: r(17px)
		padding-bottom: r(20px)
	.title-page
		text-transform: none
		margin-bottom: r(31px)
	.wrap-services-main
		.item-services
			display: flex
			flex-wrap: wrap
			margin-bottom: r(60px)
			@media (min-width: 1024px)
				&:nth-child(2n-1)
					.img-services
					.content-services
						padding-left: r(75px)
				&:nth-child(2n)
					flex-direction: row-reverse
					.img-services
					.content-services
						padding-right: r(75px)
		.img-services
			flex: 0 0 59.7560%
			max-width: 59.7560%
			transition: .3s all ease-in-out
			@media (max-width: 1023.98px)
				flex: 0 0 100%
				max-width: 100%
				margin-bottom: 30px
			&:hover
				box-shadow: 8px 8px 0px $main
			img
				width: 100%
				height: 100%
				object-fit: cover
		.content-services
			flex: 0 0 40.244%
			max-width: 40.244%
			display: flex
			flex-direction: column
			justify-content: center
			@media (max-width: 1023.98px)
				flex: 0 0 100%
				max-width: 100%
			@media (max-width: 575.98px)
				*
					text-align: justify
			.title-services
				color: $text
				font-size: r(20px)
				font-weight: 700
				margin-bottom: r(30px)
			ul
				+type-none()
				li
					display: flex
					line-height: 2.25
					&+li
						margin-top: 0px
					&::before
						content: 'lens'
						color: #999999
						font-family: "Material Icons"
						font-size: 8px
						padding-top: 10px
						margin-right: 20px
.other-services
	background-color: $gray
	padding-top: r(30px)
	padding-bottom: r(30px)
	@media (min-width: 1024px)
		padding-top: r(68px)
		padding-bottom: r(69px)
	.title-page
		margin-bottom: r(29px)
	.item-home-5
		height: 300px !important
		margin-bottom: 30px
		@media (max-width: 1023.98px)
			height: auto !important
		.title
			@media (min-width: 1024px)
				bottom: 32px
				left: 39px
