.zone-tab-gt
	background-color: #fff
.page-gioi-thieu
	&.section-1
		margin-top: -45px
		padding-top: r(66px) !important
		@media (min-width: 1200px)
			padding-bottom: r(100px)
		.title-page
			margin-bottom: 25px
		.desc-main
			max-width: 950px
			margin: 0 auto
			margin-top: 10px
			text-align: center
		.section-1-wrap-info
			max-width: 914px
			margin: 0 auto
			margin-top: 20px
			.swiper-container
				padding: 10px 0px
			.item-box-gt-1
				box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05)
				background-color: #ffffff
				height: 270px
				display: flex
				flex-direction: column
				align-items: center
				text-align: center
				justify-content: center
				padding: 0 19px
				line-height: 1.66666
				transition: .3s all ease-in-out
				&:hover
					transform: translateY(-10px)
					box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.2)
				.circle
					width: 70px
					height: 70px
					background-color: $main
					background-image: linear-gradient(-145deg, #079f4a 0%, $main 100%)
					display: flex
					align-items: center
					justify-content: center
					border-radius: 50%
					padding: 5px
				.title
					color: $black
					font-size: 12px
					font-weight: 800
					text-transform: uppercase
					margin-top: 15px
				.desc
					font-size: 12px
					margin-top: 12px
	&.section-2
		position: relative
		.img
			height: 100%
			@media (max-width: 1023.98px)
				height: 400px
			img
				height: 100%
				width: 100%
				object-fit: cover
		.box
			position: absolute
			left: 0
			top: 0
			height: 100%
			background-color: $main
			width: 43vw
			background-image: url('../img/background/bggt-3.png')
			background-repeat: no-repeat
			border-radius: 0 50px 50px 0
			overflow: hidden
			@media (max-width: 1279.98px)
				width: 50vw
			@media (max-width: 1023.98px)
				width: 68vw
			@media (max-width: 767.98px)
				width: 100vw
				border-radius: 0px
		.container
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			padding-top: 50px
		.item-gt-2
			display: flex
			margin-bottom: 45px
			padding-right: 60px
			@media (max-width: 1279.98px)
				padding-right: 20px
			@media (max-width: 1023.98px)
				padding-right: r(50px)
			@media (max-width: 767.98px)
				padding-right: 0
			.icon
				flex: 0 0 55px
				margin-right: 20px
			.text
				flex: 1
				border-left: 1px solid white
				padding-left: 20px
				.title
					color: #ffffff
					font-size: r(30px)
					font-weight: 600
					text-transform: uppercase
					margin-bottom: 10px
					@media (max-width: 1279.98px)
						font-size: r(25px)
				.desc
					color: #ffffff
					font-weight: 400
					@media (max-width: 1279.98px)
						font-size: r(14px)
	&.section-3
		@media (min-width: 1200px)
			padding-top: r(70px)
			padding-bottom: r(80px)
		.title-page
			margin-bottom: 28px
		.thumbnail-history-main
			position: relative
			max-width: 1020px
			margin: 0 auto
			position: relative
			margin-bottom: 38px
			.swiper-slide-thumb-active
				.item-year
					background-color: $main
					.text
						color: white
			.btn
				top: 50% !important
			&::before
				content: ''
				position: absolute
				top: 50%
				transform: translateY(-50%)
				left: 0
				width: 100%
				pointer-events: none
				height: 1px
				background-color: $line
			.swiper-container
				max-width: 870px
			.item-year
				width: 70px
				height: 70px
				background-color: #f3f3f3
				display: flex
				align-items: center
				justify-content: center
				border-radius: 50%
				margin: 0 auto
				transition: .3s all ease-in-out
				cursor: pointer
				&:hover
					background-color: $main
					.text
						color: white
				.text
					color: $black
					font-size: 1rem
					font-weight: 700
					transition: .3s all ease-in-out
	.slide-main-history
		.swiper-container
			padding-bottom: 20px
	.wrap-content-history
		display: flex
		flex-wrap: wrap
		.img-main
			flex: 0 0 59.7560%
			max-width: 59.7560%
			transition: .3s all ease-in-out
			@media (max-width: 767.98px)
				flex: 0 0 100%
				max-width: 100%
			&:hover
				box-shadow: 8px 8px 0px $main
			a
				width: 100%
				height: 420px
				img
					width: 100%
					height: 100%
					object-fit: cover
		.content-main
			display: flex
			flex-direction: column
			justify-content: center
			flex: 0 0 40.244%
			max-width: 40.244%
			padding-left: 75px
			padding-right: 100px
			padding-bottom: r(84px)
			@media (max-width: 1279.98px)
				padding: 30px
				padding-bottom: r(50px)
			@media (max-width: 767.98px)
				flex: 0 0 100%
				max-width: 100%
			.title-year
				color: $black
				font-size: r(48px)
				font-weight: 800
				text-transform: uppercase
				position: relative
				padding-bottom: 10px
				margin-bottom: 30px
				&::before
					content: ''
					position: absolute
					top: 100%
					left: 0
					width: 77px
					height: 2px
					background-color: $main
	&.section-4
		background-color: $gray
		@media (min-width: 1200px)
			padding-top: r(70px)
			padding-bottom: r(100px)
		.title-page
			margin-bottom: 30px
		.item-staff
			.img
				+img-ratio(299/285)
			.title
				color: $black
				font-size: 1rem
				font-weight: 700
				margin-top: 15px
				text-transform: uppercase
			.position
				color: #999999
				font-size: r(14px)
				font-weight: 400
				margin-top: 10px
	&.section-5
		background-color: #ffffff
		@media (min-width: 1200px)
			padding-top: r(70px)
			padding-bottom: r(77px)
		.item-ccgt
			a
				display: flex
				width: 100%
				height: 115px
				align-items: center
				justify-content: center
				border: 1px solid #eeeeee
				transition: .3s all ease-in-out
				&:hover
					border: 1px solid $main
				img
					max-width: 100%
					max-height: 100%
					object-fit: contain
			.title
				color: $black
				font-size: r(14px)
				font-weight: 400
				margin-top: 19px
				text-align: center
				&:hover
					color: $main
