.page-cong-trinh-detail
	.wrap-title-header
		@extend .padding-blog
		margin-top: r(22px)
		.title
			color: $main
			font-size: r(30px)
			font-weight: 600
			margin-top: r(2px)
		.date-social
			display: flex
			align-items: center
			justify-content: space-between
			.date
				color: $gray-2
				font-size: r(14px)
				font-weight: 400
				line-height: 0.01px
			.social
				width: r(40px)
				height: r(40px)
				border: 1px solid $line
				display: flex
				align-items: center
				border-radius: 50%
				justify-content: center
				padding-top: 3px
				transition: .3s all ease-in-out
				&:hover
					background-color: $main
					border: 1px solid $green-hover
					box-shadow: 2px 2px 0px $green-hover
					em
						color: white
				em
					font-size: r(18px)
					color: #999999
					transition: .3s all ease-in-out
	.wrap-main-product
		margin-top: r(30px)
		margin-bottom: 4rem
		@media (max-width: 1023.98px)
			margin-bottom: 2rem
		.wrap-main-img
			.img
				a
					+img-ratio(690/1230)
	.wrap-main-thumb
		max-width: 980px
		margin: 0 auto
		position: relative
		margin-top: 20px
		.btn
			top: 50% !important
		.swiper-slide-thumb-active
			.img
				border: 2px solid $main
		.img
			width: 100%
			height: 101px
			border: 2px solid transparent
			transition: .3s all ease-in-out
			img
				width: 100%
				height: 100%
				object-fit: cover
	.wrap-content-project
		border-top: 3px solid $main
		margin-top: r(80px)
		@media (max-width: 1023.98px)
			margin-top: r(50px)
		table
			width: 100%
			tr
				@media (max-width: 575.98px)
					display: flex
					flex-direction: column
			td
				border-bottom: 1px solid $line
				padding: 17.5px 0px
				text-transform: uppercase
				&:first-child
					font-weight: bold
					padding-left: r(103px)
					width: 31.5%
					white-space: nowrap
					@media (max-width: 1023.98px)
						padding-left: r(60px)
					@media (max-width: 575.98px)
						width: 100%
						padding-left: r(20px)
				&:last-child
					padding-left: 20px
					@media (max-width: 1023.98px)
						padding-right: 40px
						padding-left: 40px
					@media (max-width: 575.98px)
						padding-left: 20px
.other-project-main
	padding-bottom: r(35px)
	.item-cong-trinh
		.img
			&:hover
				box-shadow: none !important
