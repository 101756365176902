.mx-auto
	margin: 0 auto
.ml-auto
	margin-left: auto
.mr-auto
	margin-right: auto
.d-flex
	display: flex
	align-items: center
.flex-column
	flex-direction: column
.flex-row
	flex-direction: row
.flex-wrap
	flex-wrap: wrap
.justify-between
	justify-content: space-between
.justify-center
	justify-content: center
.justify-start
	justify-content: flex-start
.justify-end
	justify-content: flex-end
.align-center
	align-items: center
.relative
	position: relative
.align-start
	align-items: flex-start
.text-center
	text-align: center
.text-right
	text-align: right
.flex-wrap
	flex-wrap: wrap
.button-prev, .button-next
	cursor: pointer
.line-bottom
	border-bottom: 1px solid rgba($main, 0.28)
.text-uppercase
	text-transform: uppercase
.text-capitalize
	text-transform: capitalize
.text-center
	text-align: center
.ovh
	overflow: hidden
.color-white
	color: white
.color-gray
	color: #999999
.color-text
	color: #333333
.color-main
	color: $main
.f-12
	font-size: 12/16*1rem
.f-14
	font-size: 14/16*1rem
.f-16
	font-size: 1rem
.f-18
	font-size: 18/16*1rem
.f-20
	font-size: 20/16*1rem
.f-22
	font-size: 22/16*1rem
.f-24
	font-size: 24/16*1rem
.f-25
	font-size: 25/16*1rem
.f-30
	font-size: 30/16*1rem
.f-36
	font-size: 36/16*1rem
.f-38
	font-size: 38/16*1rem
.f-40
	font-size: 40/16*1rem
.fw-300
	font-weight: 300
.fw-400
	font-weight: 400
.fw-500
	font-weight: 500
.fw-600
	font-weight: 600
.fw-700
	font-weight: 700
.fw-800
	font-weight: 800
.fw-900
	font-weight: 900
.line-30
	line-height: 30px
.p-0
	padding: 0
.pl-0
	padding-left: 0
.pr-0
	padding-right: 0
.m-0
	margin: 0
.text-left
	text-align: left
.transition
	transition: all 1s cubic-bezier(.22, .44, 0, 1)
.hover-text
	transition: .3s all ease-in-out
	&:hover
		color: $main
.btn-center
	display: flex
	align-items: center
	justify-content: center
.title-hover
	transition: .3s all ease-in-out
	&:hover
		color: $main
.hover-input
	input
		transition: .3s all ease-in-out
		&:hover,&:focus
			border: 1px solid $main !important
			box-shadow: 4px 4px 0px $main !important
	textarea
		transition: .3s all ease-in-out
		&:hover,&:focus
			border: 1px solid $main !important
			box-shadow: 4px 4px 0px $main !important
