body {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 100%; }

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 1024px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1280px) {
  .container {
    max-width: 1260px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.no-gutters > *,
.no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

[class*="col-"] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1024px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1280px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  background-color: #cccccc;
  border-radius: 20px; }

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer; }

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  background-color: #14713d;
  cursor: pointer; }

::selection {
  background: #14713d;
  color: white; }

main {
  padding-top: 119px; }
  @media (max-width: 1023.98px) {
    main {
      padding-top: 64px; } }

html {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  color: #333333;
  margin-top: 0;
  margin-bottom: 0; }

body, html {
  font-size: 13px; }
  @media (min-width: 1024px) {
    body, html {
      font-size: 14px; } }
  @media (min-width: 1280px) {
    body, html {
      font-size: 15px; } }
  @media (min-width: 1600px) {
    body, html {
      font-size: 16px; } }

* {
  box-sizing: border-box; }
  *::before, *::after {
    box-sizing: border-box; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0;
  margin-left: 0; }

blockquote {
  margin: 0; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  text-decoration: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: inherit; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: none;
  outline: 5px auto -webkit-focus-ring-color; }

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

select {
  word-wrap: normal; }

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden], .d-none, .hidden {
  display: none !important; }

input:focus, button:focus, a:focus, .button-prev:focus, .button-next:focus, textarea:focus, select:focus {
  outline: none; }

img {
  max-width: 100%;
  height: auto; }

.zoom-img {
  overflow: hidden; }
  .zoom-img:hover img {
    transform: scale(1.05) translateZ(0); }
  .zoom-img img {
    transition: .3s all ease-in-out; }

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(40px); }
  to {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes fade-in2 {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(20px); }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0px); } }

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(45px);
    filter: blur(5); }
  100% {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0); } }

@keyframes blurText {
  0% {
    filter: blur(2px); }
  100% {
    filter: blur(2px); } }

@keyframes loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes loading1-circle {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -600; } }

#load {
  width: 50px;
  display: inline-block;
  animation: loading 2s linear infinite; }

#loading-inner {
  stroke-dashoffset: 1;
  stroke-dasharray: 300;
  stroke-width: 10;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  animation: loading1-circle 2s linear infinite;
  stroke: #14713d;
  fill: transparent; }

@keyframes AlertError {
  0% {
    transform: translateX(30px); }
  20% {
    transform: translateX(-30px); }
  40% {
    transform: translateX(15px); }
  60% {
    transform: translateX(-15px); }
  80% {
    transform: translateX(8px); }
  100% {
    transform: translateX(0px); } }

.shine {
  position: relative;
  overflow: hidden; }
  .shine::before {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    content: "";
    display: block;
    height: 100%;
    left: -75%;
    position: absolute;
    top: 0;
    transform: skewX(-25deg);
    width: 50%;
    z-index: 2; }
  .shine:hover::before {
    -webkit-animation: shine 0.85s;
    animation: shine 0.85s; }

@-webkit-keyframes shine {
  100% { }
  left: 125%; }

@keyframes shine {
  100% {
    left: 125%; } }

.mx-auto {
  margin: 0 auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.d-flex {
  display: flex;
  align-items: center; }

.flex-column {
  flex-direction: column; }

.flex-row {
  flex-direction: row; }

.flex-wrap {
  flex-wrap: wrap; }

.justify-between {
  justify-content: space-between; }

.justify-center {
  justify-content: center; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.align-center {
  align-items: center; }

.relative {
  position: relative; }

.align-start {
  align-items: flex-start; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.flex-wrap {
  flex-wrap: wrap; }

.button-prev, .button-next {
  cursor: pointer; }

.line-bottom {
  border-bottom: 1px solid rgba(20, 113, 61, 0.28); }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-center {
  text-align: center; }

.ovh {
  overflow: hidden; }

.color-white {
  color: white; }

.color-gray {
  color: #999999; }

.color-text {
  color: #333333; }

.color-main {
  color: #14713d; }

.f-12 {
  font-size: 0.75rem; }

.f-14 {
  font-size: 0.875rem; }

.f-16 {
  font-size: 1rem; }

.f-18 {
  font-size: 1.125rem; }

.f-20 {
  font-size: 1.25rem; }

.f-22 {
  font-size: 1.375rem; }

.f-24 {
  font-size: 1.5rem; }

.f-25 {
  font-size: 1.5625rem; }

.f-30 {
  font-size: 1.875rem; }

.f-36 {
  font-size: 2.25rem; }

.f-38 {
  font-size: 2.375rem; }

.f-40 {
  font-size: 2.5rem; }

.fw-300 {
  font-weight: 300; }

.fw-400 {
  font-weight: 400; }

.fw-500 {
  font-weight: 500; }

.fw-600 {
  font-weight: 600; }

.fw-700 {
  font-weight: 700; }

.fw-800 {
  font-weight: 800; }

.fw-900 {
  font-weight: 900; }

.line-30 {
  line-height: 30px; }

.p-0 {
  padding: 0; }

.pl-0 {
  padding-left: 0; }

.pr-0 {
  padding-right: 0; }

.m-0 {
  margin: 0; }

.text-left {
  text-align: left; }

.transition {
  transition: all 1s cubic-bezier(0.22, 0.44, 0, 1); }

.hover-text {
  transition: .3s all ease-in-out; }
  .hover-text:hover {
    color: #14713d; }

.btn-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.title-hover {
  transition: .3s all ease-in-out; }
  .title-hover:hover {
    color: #14713d; }

.hover-input input, .wrap-map-form .wrap-form-main .form-group input {
  transition: .3s all ease-in-out; }
  .hover-input input:hover, .wrap-map-form .wrap-form-main .form-group input:hover, .hover-input input:focus, .wrap-map-form .wrap-form-main .form-group input:focus {
    border: 1px solid #14713d !important;
    box-shadow: 4px 4px 0px #14713d !important; }

.hover-input textarea, .wrap-map-form .wrap-form-main .form-group textarea {
  transition: .3s all ease-in-out; }
  .hover-input textarea:hover, .wrap-map-form .wrap-form-main .form-group textarea:hover, .hover-input textarea:focus, .wrap-map-form .wrap-form-main .form-group textarea:focus {
    border: 1px solid #14713d !important;
    box-shadow: 4px 4px 0px #14713d !important; }

.btn {
  cursor: pointer;
  user-select: none; }
  .btn.btn-main {
    display: flex;
    padding: 0 1.375rem;
    height: 2.8125rem;
    border-radius: 23px;
    background-color: #14713d;
    align-items: center;
    justify-content: center;
    min-width: 10.625rem;
    transition: .3s all ease-in-out; }
    .btn.btn-main.grey {
      background-color: #f8f8f8; }
      .btn.btn-main.grey span {
        color: #333333; }
      .btn.btn-main.grey em {
        color: #111111; }
    .btn.btn-main:hover {
      transform: translateY(-3px) scale(1.02) translateZ(0);
      box-shadow: 4px 4px 0px #0a552c; }
    .btn.btn-main span {
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 500;
      margin-right: 6px; }
    .btn.btn-main em {
      color: white;
      font-size: 18px; }
  .btn.btn-sw-1 {
    width: 17px;
    height: 31px;
    top: 38% !important; }
  .btn.btn-sw-2 {
    filter: brightness(0) invert(1);
    width: 17px;
    height: 31px; }
  .btn.btn-view-detail {
    display: flex;
    align-items: center; }
    .btn.btn-view-detail:hover span, .btn.btn-view-detail:hover em {
      color: #14713d; }
    .btn.btn-view-detail span {
      color: #333333;
      font-size: 0.875rem;
      font-weight: 500; }
    .btn.btn-view-detail em {
      color: #333333;
      margin-left: 15px; }
  .btn.btn-contact {
    width: 170px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 23px;
    background-color: #14713d;
    border: 2px solid transparent;
    transition: .3s all ease-in-out; }
    .btn.btn-contact:hover {
      border: 2px solid #14713d;
      background-color: #fff;
      box-shadow: 2px 2px 0px #14713d; }
      .btn.btn-contact:hover em, .btn.btn-contact:hover span {
        color: #14713d; }
    .btn.btn-contact em {
      color: white;
      margin-right: 15px;
      font-size: 18px;
      transition: .3s all ease-in-out; }
    .btn.btn-contact span {
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 500;
      transition: .3s all ease-in-out; }

.wrap-button-slide {
  display: flex;
  align-items: center;
  justify-content: center; }
  .wrap-button-slide .btn-prev, .wrap-button-slide .btn-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 575.98px) {
      .wrap-button-slide .btn-prev, .wrap-button-slide .btn-next {
        display: none; } }
  .wrap-button-slide .btn-prev {
    right: 100%;
    margin-right: 30px; }
    @media (max-width: 1300px) {
      .wrap-button-slide .btn-prev {
        margin-right: 10px; } }
  .wrap-button-slide .btn-next {
    left: 100%;
    margin-left: 30px; }
    @media (max-width: 1300px) {
      .wrap-button-slide .btn-next {
        margin-left: 10px; } }

.wrap-slide-main {
  position: relative; }

.btn-left {
  display: flex;
  justify-content: flex-start; }

.item-product-main .img {
  background-color: white;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.08); }
  .item-product-main .img a {
    width: 100%;
    display: flex;
    height: 285px;
    align-items: center;
    padding: 30px;
    justify-content: center; }
    @media (max-width: 1279.98px) {
      .item-product-main .img a {
        height: 230px; } }
    @media (max-width: 767.98px) {
      .item-product-main .img a {
        padding: 15px;
        height: 180px; } }
    .item-product-main .img a:hover img {
      transform: scale(1.05); }
    .item-product-main .img a img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      transition: .3s all ease-in-out; }

.item-product-main .content .product-title-cate {
  display: block;
  margin-top: 8px;
  margin-bottom: 5px; }
  .item-product-main .content .product-title-cate span {
    color: #14713d;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    position: relative; }
    .item-product-main .content .product-title-cate span:hover::before {
      width: 100%; }
    .item-product-main .content .product-title-cate span::before {
      content: '';
      position: absolute;
      top: 105%;
      left: 0;
      height: 1px;
      width: 0%;
      transition: .3s all ease-in-out;
      background-color: #14713d; }

.item-product-main .content .product-title-main {
  color: #333333;
  font-size: 1.125rem;
  font-weight: 600;
  transition: .3s all ease-in-out; }
  @media (max-width: 575.98px) {
    .item-product-main .content .product-title-main {
      font-size: 1rem; } }
  .item-product-main .content .product-title-main:hover {
    color: #14713d; }

.item-product-main .content .btn-left {
  display: flex;
  margin-top: 12px;
  justify-content: flex-start; }

.item-product-main .btn-main span {
  transition: .3s all ease-in-out; }

.item-product-main .btn-main em {
  transition: .3s all ease-in-out; }

.item-product-main .btn-main:hover {
  background-color: #14713d; }
  .item-product-main .btn-main:hover span {
    color: white; }
  .item-product-main .btn-main:hover em {
    color: white; }

.swiper-button-disabled {
  opacity: .5 !important; }

.item-cong-trinh {
  margin-bottom: 2.5rem; }
  .item-cong-trinh .img {
    transition: .3s all ease-in-out; }
    @media screen and (min-width: 1025px) {
      .item-cong-trinh .img:hover {
        box-shadow: 8px 8px #14713d; } }
    .item-cong-trinh .img a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 56.41026%; }
      .item-cong-trinh .img a img, .item-cong-trinh .img a iframe, .item-cong-trinh .img a video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
  .item-cong-trinh .title {
    margin: 0.9375rem 0px;
    display: block; }

@media (max-width: 575.98px) {
  .product-list-main .row {
    margin-left: -5px;
    margin-right: -5px; }
    .product-list-main .row [class*="col"] {
      padding-left: 5px;
      padding-right: 5px; } }

.main-section {
  padding: 35px 0; }
  @media (min-width: 768px) {
    .main-section {
      padding: 40px 0; } }
  @media (min-width: 1024px) {
    .main-section {
      padding: 45px 0; } }
  @media (min-width: 1200px) {
    .main-section {
      padding: 50px 0; } }

.title-main {
  color: #14713d;
  font-size: 1.875rem;
  font-weight: 600; }
  @media (max-width: 575.98px) {
    .title-main {
      font-size: 1.8rem; } }
  .title-main.white {
    color: white; }
  .title-main.black {
    color: #111111; }

.wrap-title-all {
  display: flex;
  align-items: baseline;
  justify-content: space-between; }
  .wrap-title-all .all {
    display: flex;
    align-items: center;
    color: #666666;
    font-size: 0.875rem;
    font-weight: 500; }
    .wrap-title-all .all:hover {
      color: #14713d; }
      .wrap-title-all .all:hover em {
        color: #14713d; }
    .wrap-title-all .all em {
      color: #666666;
      font-size: 20px; }

.title-page {
  color: #14713d;
  font-size: 1.875rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2.5rem; }

.wrap-lazy-slide.active .swiper-slide {
  opacity: 1;
  height: auto; }

.wrap-lazy-slide .swiper-slide {
  opacity: 0;
  height: 100px;
  transition: .3s all ease-in-out; }
  .wrap-lazy-slide .swiper-slide:nth-of-type(1) {
    transition-delay: 0.15s; }
  .wrap-lazy-slide .swiper-slide:nth-of-type(2) {
    transition-delay: 0.25s; }
  .wrap-lazy-slide .swiper-slide:nth-of-type(3) {
    transition-delay: 0.35s; }
  .wrap-lazy-slide .swiper-slide:nth-of-type(4) {
    transition-delay: 0.45s; }
  .wrap-lazy-slide .swiper-slide:nth-of-type(5) {
    transition-delay: 0.55s; }

.disable {
  overflow: hidden; }

.hambuger {
  height: 19px;
  align-items: center;
  justify-content: center;
  width: 25px;
  position: relative;
  transition: .3s all ease-in-out;
  z-index: 3;
  display: none;
  flex: 0 0 25px;
  cursor: pointer; }
  @media (max-width: 1024.98px) {
    .hambuger {
      display: flex; } }
  .hambuger.active span:first-child {
    top: 8px;
    left: 50%;
    width: 0px; }
  .hambuger.active span:nth-child(2) {
    transform: rotate(45deg);
    background: #14713d; }
  .hambuger.active span:nth-child(3) {
    transform: rotate(-45deg);
    background: #14713d; }
  .hambuger.active span:nth-child(4) {
    top: 8px;
    left: 50%;
    width: 0px; }
  .hambuger span {
    display: block;
    position: absolute;
    left: 0;
    transform: rotate(0deg);
    opacity: 1;
    transition: .5s ease-in-out;
    background: #14713d;
    width: 100%;
    height: 4px;
    border-radius: 20px; }
    .hambuger span:first-child {
      top: 0; }
    .hambuger span:nth-child(2), .hambuger span:nth-child(3) {
      top: 8px; }
    .hambuger span:nth-child(4) {
      top: 16px; }

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  transition: .3s all ease-in-out;
  pointer-events: none;
  opacity: 0; }
  .overlay.active {
    opacity: 1;
    pointer-events: auto; }

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 5rem; }
  @media (max-width: 1023.98px) {
    .pagination {
      margin-bottom: 1.875rem; } }
  .pagination li.active a, .pagination li.active span {
    background-color: #14713d;
    color: white; }
  .pagination li + li {
    margin-left: 10px; }
  .pagination li a, .pagination li span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #111111;
    font-size: 1rem;
    font-weight: 700;
    transition: .3s all ease-in-out; }
    .pagination li a:hover, .pagination li span:hover {
      background-color: #14713d;
      color: white; }

.breadcrumb-grey .global-breadcrumb {
  background-color: #f8f8f8; }

#ctl00_divAltContent2 {
  display: none; }

.swiper-pagination-1 {
  position: static !important;
  margin-top: 30px; }
  @media (min-width: 1280px) {
    .swiper-pagination-1 {
      display: none; } }
  .swiper-pagination-1 .swiper-pagination-bullet {
    width: 10px;
    height: 10px; }
    .swiper-pagination-1 .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #14713d !important; }

.fancybox-button[disabled], .fancybox-button[disabled]:hover {
  display: none; }

.search-product-wrapper .wrap-product-list {
  margin: 1.875rem 0px; }
  .search-product-wrapper .wrap-product-list .row {
    row-gap: 30px; }

.product-detail-page .pagination {
  display: none; }

.banner-home:hover .wrap-button-slide .btn {
  opacity: 1; }

.banner-home .box-banner {
  position: relative; }
  @media (min-width: 1600px) {
    .banner-home .box-banner {
      height: 550px; } }
  @media (max-width: 1023.98px) {
    .banner-home .box-banner {
      height: 40vh; } }
  .banner-home .box-banner .img {
    height: 100%; }
    .banner-home .box-banner .img::before {
      content: '';
      background-color: rgba(0, 0, 0, 0.35);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .banner-home .box-banner .img img {
      height: 100%;
      width: 100%;
      object-fit: cover; }

.banner-home .box-text {
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%); }
  @media (max-width: 1350px) {
    .banner-home .box-text {
      top: 15%; } }
  @media screen and (max-width: 768px) {
    .banner-home .box-text {
      left: 50%;
      top: 20%; } }
  .banner-home .box-text .wrap-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 550px;
    text-align: left; }
  .banner-home .box-text .title-sub {
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.9375rem;
    text-transform: uppercase; }
    @media (max-width: 1279.98px) {
      .banner-home .box-text .title-sub {
        font-size: 1rem; } }
  .banner-home .box-text .title-white {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    font-size: 2.125rem;
    line-height: 1.2;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1.875rem; }
    @media (max-width: 1279.98px) {
      .banner-home .box-text .title-white {
        font-size: 1.875rem;
        margin-bottom: 1.5625rem; } }

.banner-home .swiper-pagination {
  text-align: left;
  left: 50% !important;
  bottom: 32px;
  transform: translateX(-50%); }
  .banner-home .swiper-pagination-bullet {
    width: 10px;
    margin: 0 5px;
    height: 10px;
    border: 2px solid #ffffff;
    opacity: 1;
    background-color: transparent; }
    .banner-home .swiper-pagination-bullet-active {
      background-color: #fff; }

.banner-home .swiper-slide.swiper-slide-active .wrap-text {
  opacity: 1;
  transform: translateY(0); }

.banner-home .swiper-slide .wrap-text {
  will-change: transform, opacity;
  transform: translateY(50%);
  opacity: 0;
  transition: transform 0.5s 0.25s, opacity 0.5s 0.25s; }

.banner-home .wrap-button-slide .btn-prev, .banner-home .wrap-button-slide .btn-next {
  z-index: 10; }
  @media (min-width: 1280px) {
    .banner-home .wrap-button-slide .btn-prev, .banner-home .wrap-button-slide .btn-next {
      opacity: 0;
      transition: .3s all ease-in-out; } }

.banner-home .wrap-button-slide .btn-prev {
  right: auto;
  left: 3vw; }

.banner-home .wrap-button-slide .btn-next {
  left: auto;
  right: 3vw; }

#page-banner {
  width: 100%;
  position: relative; }
  #page-banner .banner__pages {
    width: 100%;
    height: 49vh; }
    #page-banner .banner__pages img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    @media screen and (max-width: 1024px) {
      #page-banner .banner__pages {
        height: 30vh; } }
    @media (max-width: 575.98px) {
      #page-banner .banner__pages {
        height: auto; } }

.news-detail-page #page-banner {
  display: none; }

.global-breadcrumb {
  width: 100%;
  background-color: white; }
  .global-breadcrumb .breadcrumb {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 15px 0;
    list-style: none;
    padding-bottom: 10px;
    margin-bottom: 0;
    overflow: auto; }
    .global-breadcrumb .breadcrumb::-webkit-scrollbar {
      height: 0px; }
    .global-breadcrumb .breadcrumb li {
      position: relative;
      display: flex;
      align-items: center; }
      .global-breadcrumb .breadcrumb li a {
        font-size: 12px;
        color: #999999;
        font-weight: 500;
        white-space: nowrap;
        text-decoration: none; }
        @media (max-width: 991.98px) {
          .global-breadcrumb .breadcrumb li a {
            font-size: 10px; } }
      .global-breadcrumb .breadcrumb li:hover a {
        color: #14713d; }
      .global-breadcrumb .breadcrumb li:first-child {
        padding-left: 0; }
    .global-breadcrumb .breadcrumb li + li::before {
      content: '/';
      color: #999;
      font-size: 12px;
      padding-right: 2px; }
  @media (min-width: 768.98px) {
    .global-breadcrumb {
      margin-bottom: 0; } }
  @media (min-width: 320px) {
    .global-breadcrumb {
      margin-bottom: 0; } }

.signup-page {
  background-color: #fff;
  padding: 60px 0px; }
  .signup-page .signup-panel {
    margin: 0 auto;
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06);
    padding: 15px;
    border-radius: 20px;
    max-width: 450px;
    width: 100%; }
  .signup-page .col-xs-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important; }
  .signup-page .headtitle {
    font-size: 2.25rem;
    color: #14713d;
    margin-bottom: 15px;
    text-align: center; }
  .signup-page label {
    font-size: 1.125rem; }
  .signup-page input[type="text"], .signup-page input[type="password"] {
    padding: 5px !important;
    height: 35px !important;
    border: 0 !important;
    outline: 0 !important;
    width: 100% !important;
    border-bottom: 1px solid #eaeaea !important;
    margin-bottom: 5px !important; }
  .signup-page select {
    padding: 5px !important;
    height: 35px !important;
    border: 0 !important;
    outline: 0 !important;
    width: 100% !important;
    border-bottom: 1px solid #eaeaea !important;
    margin-bottom: 5px !important; }
  .signup-page .form-group {
    margin-bottom: 15px; }
  .signup-page #ctl00_mainContent_RegisterUser___CustomNav0_StepNextButtonButton {
    width: 100%;
    background-color: #14713d;
    color: white;
    margin-top: 10px;
    outline: 0;
    border: 0;
    padding: 5px 0px; }

.wrap-form span.fa-exclamation-triangle {
  font-size: 0.875rem;
  color: red; }
  .wrap-form span.fa-exclamation-triangle::before {
    margin-right: 5px; }

.wrap-form .form-row {
  display: flex;
  margin: 0 -15px; }
  @media screen and (max-width: 768px) {
    .wrap-form .form-row {
      flex-wrap: wrap; } }
  .wrap-form .form-row .form-group {
    flex: 0 0 50%;
    padding: 0 15px; }
    @media screen and (max-width: 768px) {
      .wrap-form .form-row .form-group {
        flex: 0 0 100%; } }
    .wrap-form .form-row .form-group input[type="text"] {
      width: 100%; }

span.fa-exclamation-triangle {
  font-weight: 400 !important;
  font-style: italic;
  color: red;
  font-family: "Philosopher", sans-serif !important;
  font-size: .9rem; }
  span.fa-exclamation-triangle::before {
    display: none !important; }

.frm-btn-reset {
  display: none !important; }

.frm-captcha {
  display: flex;
  float: left;
  width: calc(100% - 233px);
  padding-right: 20px; }
  @media screen and (max-width: 1280px) {
    .frm-captcha {
      width: calc(100% - 110px); } }
  @media screen and (max-width: 1023.67px) {
    .frm-captcha {
      float: none;
      padding-right: 0;
      flex: 1;
      width: 100%; } }
  @media screen and (max-width: 767.67px) {
    .frm-captcha {
      flex-direction: column; } }
  .frm-captcha .label {
    display: none !important; }
  .frm-captcha .frm-captcha-input {
    order: 2;
    flex: 1; }
    @media screen and (max-width: 767.67px) {
      .frm-captcha .frm-captcha-input {
        order: 1;
        margin-bottom: 20px; } }
  .frm-captcha .RadCaptcha_Default {
    position: relative; }
    @media screen and (max-width: 767.67px) {
      .frm-captcha .RadCaptcha_Default {
        flex: 0 0 100%;
        order: 2; } }
  .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl00 {
    position: absolute;
    top: 120%;
    color: red; }
  .frm-captcha #ctl00_mainContent_ctl02_txtCaptcha {
    width: 100%;
    height: 40px;
    margin-top: 0;
    border: 1px solid #e1e1e1;
    background-color: #fefefe; }
    @media screen and (max-width: 767.67px) {
      .frm-captcha #ctl00_mainContent_ctl02_txtCaptcha {
        width: 100%; } }
  .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 {
    display: flex;
    align-items: center; }
    .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 img {
      width: 100% !important;
      height: 40px !important; }
    .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 .rcRefreshImage {
      font-size: 0;
      margin-left: 5px;
      height: auto !important;
      margin-right: 10px; }
      .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 .rcRefreshImage::after {
        display: none !important; }
      .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 .rcRefreshImage::before {
        content: "\e861";
        font-family: Linearicons-Free;
        color: #333333;
        font-size: 30px;
        font-weight: 400; }

.subscribefrm .fa-exclamation-triangle {
  top: 110% !important;
  right: 0 !important;
  width: auto !important;
  text-indent: 0 !important; }
  .subscribefrm .fa-exclamation-triangle::after {
    display: none; }

.login-panel {
  background-color: #fff;
  margin: 0 auto;
  box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06);
  padding: 30px;
  border-radius: 20px; }
  .login-panel .headtitle {
    font-size: 2.25rem;
    text-align: center;
    color: #14713d; }
  .login-panel .panel-heading {
    text-align: center;
    margin-bottom: 20px; }
  .login-panel .btn-login input {
    width: 100%;
    background-color: #14713d;
    color: white;
    font-size: 1.125rem;
    padding: 5px 0px;
    border: 0;
    outline: none;
    margin: 9px 0px;
    transition: .3s all ease-in-out; }
    .login-panel .btn-login input:hover {
      background-color: red; }
  .login-panel .form-group {
    display: flex;
    width: 100%; }
    .login-panel .form-group.forget {
      display: flex;
      align-items: center; }
      .login-panel .form-group.forget input {
        margin-right: 10px; }
    .login-panel .form-group:nth-child(1), .login-panel .form-group:nth-child(2) {
      border-bottom: 1px solid #eaeaea;
      margin-bottom: 10px;
      align-items: center;
      padding: 5px 0px; }
      .login-panel .form-group:nth-child(1) label i, .login-panel .form-group:nth-child(2) label i {
        color: #14713d; }
      .login-panel .form-group:nth-child(1) input, .login-panel .form-group:nth-child(2) input {
        width: 100%;
        flex: 0 0 100%;
        border: 0;
        padding-left: 15px;
        outline: 0; }
    .login-panel .form-group:nth-child(5) {
      display: flex;
      justify-content: space-between; }
      .login-panel .form-group:nth-child(5) a {
        transition: .3s all ease-in-out; }
        .login-panel .form-group:nth-child(5) a:hover {
          color: #14713d; }
  .login-panel .panel-primary {
    width: 100%; }

.login-page {
  background-color: #fff;
  padding: 40px 0px; }

.loginpage main {
  padding-top: 90px; }

.loginpage .col-xs-12 {
  margin: 0 auto; }

.passwordrecovery .admin-content {
  background-color: #fff;
  padding: 60px 0px; }
  .passwordrecovery .admin-content .wrap-secure {
    margin: 0 auto;
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06);
    padding: 15px;
    border-radius: 20px;
    max-width: 350px;
    width: 100%; }

.passwordrecovery .col-sm-9 {
  flex: 0 0 100%;
  max-width: 100%; }

.passwordrecovery .panel-heading * {
  text-align: center;
  color: #14713d;
  font-size: 2.25rem; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_UserName {
  border: 0;
  outline: none;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  padding: 5px 0px; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_CaptchaTextBox {
  border: 0;
  outline: none;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  padding: 5px 0px; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_lblEnterUserName {
  white-space: nowrap;
  text-align: left;
  margin-bottom: 5px; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_SubmitButton {
  width: 100%;
  background-color: #14713d;
  color: white;
  margin-top: 10px;
  outline: 0;
  border: 0;
  padding: 5px 0px; }

.passwordrecovery #ctl00_mainContent_lblMailError {
  font-weight: 400 !important;
  font-style: italic;
  color: red;
  font-family: "Philosopher", sans-serif !important;
  font-size: .9rem;
  margin-top: 5px;
  padding: 0 15px; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_ctl00 {
  font-weight: 400 !important;
  font-style: italic;
  color: red;
  font-family: "Philosopher", sans-serif !important;
  font-size: .9rem; }

.search-page {
  padding: 40px 0; }
  .search-page h1 {
    letter-spacing: -2px;
    margin-bottom: 20px;
    font-size: 45px;
    line-height: 55px;
    font-weight: 500;
    color: #333333; }
    @media (max-width: 1024px) {
      .search-page h1 {
        font-size: 24px;
        line-height: 30px; } }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: '\f349';
      font-family: "Material Design Icons";
      top: 0;
      right: 10px;
      z-index: 1;
      font-size: 24px;
      color: #4285f4;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .search-page .searchcontrols .form-inline .form-group:nth-child(2), .search-page .searchcontrols .form-inline .form-group:nth-child(3) {
      display: none; }
    .search-page .searchcontrols .form-inline .form-group:nth-child(4) {
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 11; }
      .search-page .searchcontrols .form-inline .form-group:nth-child(4)::before {
        content: "\F0CD";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-family: remixicon !important;
        color: #14713d; }
  .search-page .searchcontrols .frm-btn {
    position: absolute;
    top: 0;
    right: 10px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 54px;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 44px;
    border-radius: 22px;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    outline: none;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-size: 0; }
  .search-page .text-danger {
    margin-top: 20px;
    font-size: 15px;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresultsummary {
    margin-bottom: 20px; }
  .search-page .searchresults {
    margin-top: 30px; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 20px; }
  .search-page .searchresult {
    margin-bottom: 30px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 16px;
    border-radius: 8px; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 18px;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 10px; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
        .search-page .searchresult h3 a:hover {
          text-decoration: underline; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 10px; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }

.page-cong-trinh {
  padding-bottom: 0.375rem;
  padding-top: 1.25rem; }
  @media (min-width: 1200px) {
    .page-cong-trinh {
      padding-top: 1.5625rem; } }
  .page-cong-trinh .title-main {
    margin-bottom: 1.875rem; }

.page-service-main {
  padding-top: 0.625rem !important; }
  @media (min-width: 1200px) {
    .page-service-main {
      padding-top: 2.5rem !important;
      padding-bottom: 5rem !important; } }
  @media (max-width: 767.98px) {
    .page-service-main .title-main {
      margin-bottom: 1.25rem !important; } }

@media (min-width: 1024px) {
  .page-doi-tac {
    padding-bottom: 5rem;
    padding-top: 5rem; } }

.page-doi-tac .wrap-flex {
  display: flex;
  flex-wrap: wrap; }
  .page-doi-tac .wrap-flex .wrap-left-dt {
    flex: 0 0 58.3739%;
    max-width: 58.3739%; }
    @media (max-width: 1023.98px) {
      .page-doi-tac .wrap-flex .wrap-left-dt {
        flex: 0 0 100%;
        max-width: 100%; } }
  .page-doi-tac .wrap-flex .wrap-right-dt {
    flex: 0 0 41.6261%;
    max-width: 41.6261%;
    padding-left: 90px; }
    @media (max-width: 1279.98px) {
      .page-doi-tac .wrap-flex .wrap-right-dt {
        padding-left: 30px; } }
    @media (max-width: 1023.98px) {
      .page-doi-tac .wrap-flex .wrap-right-dt {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0; } }

.page-doi-tac .wrap-title-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px; }
  .page-doi-tac .wrap-title-arrow .wrap-button {
    display: flex;
    align-items: center; }
    .page-doi-tac .wrap-title-arrow .wrap-button .btn-left {
      margin-right: 10px; }

.page-doi-tac .wrap-img-dt {
  border: 1px solid #eeeeee; }
  .page-doi-tac .wrap-img-dt .swiper-slide-thumb-active .img-dt {
    transition: .3s all ease-in-out;
    box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.08);
    z-index: 10;
    position: relative; }
  .page-doi-tac .wrap-img-dt .img-dt {
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 114px;
    justify-content: center;
    transition: .3s all ease-in-out;
    padding: 5px; }
    .page-doi-tac .wrap-img-dt .img-dt img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain; }
    .page-doi-tac .wrap-img-dt .img-dt:hover {
      transition: .3s all ease-in-out;
      box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.08);
      z-index: 10;
      position: relative; }

.page-doi-tac .item-info-dt {
  background-image: url("../img/bgdt.png");
  background-size: cover;
  width: 100%;
  height: 403px;
  padding: 60px;
  margin-top: 12px; }
  @media (max-width: 1279.98px) {
    .page-doi-tac .item-info-dt {
      padding: 1.875rem; } }
  .page-doi-tac .item-info-dt h2 {
    color: #111111;
    font-size: 2.25rem;
    font-weight: 600; }
  .page-doi-tac .item-info-dt p {
    color: #111111;
    font-size: 1rem;
    margin-top: 20px;
    font-weight: 400; }

.page-doi-tac .title-main {
  text-transform: uppercase; }

.category-page-gt {
  position: fixed;
  top: 100px;
  left: -100%;
  opacity: 0;
  visibility: hidden;
  transition: all 600ms cubic-bezier(0.825, 0.09, 0.085, 1.01); }
  .category-page-gt.show-p {
    left: 0;
    opacity: 1;
    visibility: visible;
    z-index: 10;
    width: 300px; }
  .category-page-gt.active .title-header {
    width: 100%;
    opacity: 1;
    visibility: visible; }
  .category-page-gt.active .list-menu-page {
    width: 100%;
    padding-left: 10px;
    opacity: 1;
    visibility: visible;
    max-height: 100%; }
  .category-page-gt .title-header {
    display: none;
    align-items: center;
    background-color: #14713d;
    padding: 7px 10px;
    width: 43px;
    cursor: pointer;
    overflow: hidden;
    transition: .3s all ease-in-out;
    display: flex; }
    .category-page-gt .title-header span {
      font-weight: bold;
      color: white;
      white-space: nowrap; }
    .category-page-gt .title-header em {
      color: white;
      margin-right: 10px; }
  .category-page-gt .list-menu-page {
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    list-style: none;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 2px 3px 10px #0000001c;
    overflow: hidden;
    width: 0;
    max-height: 0;
    padding-left: 0;
    opacity: 0;
    visibility: hidden;
    transition: .3s all ease-in-out; }
    .category-page-gt .list-menu-page li {
      display: flex;
      align-items: center; }
      .category-page-gt .list-menu-page li.active a {
        color: #14713d; }
      .category-page-gt .list-menu-page li a {
        white-space: nowrap; }
        .category-page-gt .list-menu-page li a.active {
          color: #14713d; }
      .category-page-gt .list-menu-page li::before {
        content: 'keyboard_arrow_right';
        font-family: 'Material Icons';
        font-size: 18px;
        color: #14713d;
        margin-right: 5px; }

.zone-tab-gt {
  background-color: #fff; }

.page-gioi-thieu.section-1 {
  margin-top: -45px;
  padding-top: 4.125rem !important; }
  @media (min-width: 1200px) {
    .page-gioi-thieu.section-1 {
      padding-bottom: 6.25rem; } }
  .page-gioi-thieu.section-1 .title-page {
    margin-bottom: 25px; }
  .page-gioi-thieu.section-1 .desc-main {
    max-width: 950px;
    margin: 0 auto;
    margin-top: 10px;
    text-align: center; }
  .page-gioi-thieu.section-1 .section-1-wrap-info {
    max-width: 914px;
    margin: 0 auto;
    margin-top: 20px; }
    .page-gioi-thieu.section-1 .section-1-wrap-info .swiper-container {
      padding: 10px 0px; }
    .page-gioi-thieu.section-1 .section-1-wrap-info .item-box-gt-1 {
      box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
      height: 270px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 0 19px;
      line-height: 1.66666;
      transition: .3s all ease-in-out; }
      .page-gioi-thieu.section-1 .section-1-wrap-info .item-box-gt-1:hover {
        transform: translateY(-10px);
        box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.2); }
      .page-gioi-thieu.section-1 .section-1-wrap-info .item-box-gt-1 .circle {
        width: 70px;
        height: 70px;
        background-color: #14713d;
        background-image: linear-gradient(-145deg, #079f4a 0%, #14713d 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 5px; }
      .page-gioi-thieu.section-1 .section-1-wrap-info .item-box-gt-1 .title {
        color: #111111;
        font-size: 12px;
        font-weight: 800;
        text-transform: uppercase;
        margin-top: 15px; }
      .page-gioi-thieu.section-1 .section-1-wrap-info .item-box-gt-1 .desc {
        font-size: 12px;
        margin-top: 12px; }

.page-gioi-thieu.section-2 {
  position: relative; }
  .page-gioi-thieu.section-2 .img {
    height: 100%; }
    @media (max-width: 1023.98px) {
      .page-gioi-thieu.section-2 .img {
        height: 400px; } }
    .page-gioi-thieu.section-2 .img img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .page-gioi-thieu.section-2 .box {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #14713d;
    width: 43vw;
    background-image: url("../img/background/bggt-3.png");
    background-repeat: no-repeat;
    border-radius: 0 50px 50px 0;
    overflow: hidden; }
    @media (max-width: 1279.98px) {
      .page-gioi-thieu.section-2 .box {
        width: 50vw; } }
    @media (max-width: 1023.98px) {
      .page-gioi-thieu.section-2 .box {
        width: 68vw; } }
    @media (max-width: 767.98px) {
      .page-gioi-thieu.section-2 .box {
        width: 100vw;
        border-radius: 0px; } }
  .page-gioi-thieu.section-2 .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 50px; }
  .page-gioi-thieu.section-2 .item-gt-2 {
    display: flex;
    margin-bottom: 45px;
    padding-right: 60px; }
    @media (max-width: 1279.98px) {
      .page-gioi-thieu.section-2 .item-gt-2 {
        padding-right: 20px; } }
    @media (max-width: 1023.98px) {
      .page-gioi-thieu.section-2 .item-gt-2 {
        padding-right: 3.125rem; } }
    @media (max-width: 767.98px) {
      .page-gioi-thieu.section-2 .item-gt-2 {
        padding-right: 0; } }
    .page-gioi-thieu.section-2 .item-gt-2 .icon {
      flex: 0 0 55px;
      margin-right: 20px; }
    .page-gioi-thieu.section-2 .item-gt-2 .text {
      flex: 1;
      border-left: 1px solid white;
      padding-left: 20px; }
      .page-gioi-thieu.section-2 .item-gt-2 .text .title {
        color: #ffffff;
        font-size: 1.875rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 10px; }
        @media (max-width: 1279.98px) {
          .page-gioi-thieu.section-2 .item-gt-2 .text .title {
            font-size: 1.5625rem; } }
      .page-gioi-thieu.section-2 .item-gt-2 .text .desc {
        color: #ffffff;
        font-weight: 400; }
        @media (max-width: 1279.98px) {
          .page-gioi-thieu.section-2 .item-gt-2 .text .desc {
            font-size: 0.875rem; } }

@media (min-width: 1200px) {
  .page-gioi-thieu.section-3 {
    padding-top: 4.375rem;
    padding-bottom: 5rem; } }

.page-gioi-thieu.section-3 .title-page {
  margin-bottom: 28px; }

.page-gioi-thieu.section-3 .thumbnail-history-main {
  position: relative;
  max-width: 1020px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 38px; }
  .page-gioi-thieu.section-3 .thumbnail-history-main .swiper-slide-thumb-active .item-year {
    background-color: #14713d; }
    .page-gioi-thieu.section-3 .thumbnail-history-main .swiper-slide-thumb-active .item-year .text {
      color: white; }
  .page-gioi-thieu.section-3 .thumbnail-history-main .btn {
    top: 50% !important; }
  .page-gioi-thieu.section-3 .thumbnail-history-main::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    pointer-events: none;
    height: 1px;
    background-color: #e1e1e1; }
  .page-gioi-thieu.section-3 .thumbnail-history-main .swiper-container {
    max-width: 870px; }
  .page-gioi-thieu.section-3 .thumbnail-history-main .item-year {
    width: 70px;
    height: 70px;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;
    transition: .3s all ease-in-out;
    cursor: pointer; }
    .page-gioi-thieu.section-3 .thumbnail-history-main .item-year:hover {
      background-color: #14713d; }
      .page-gioi-thieu.section-3 .thumbnail-history-main .item-year:hover .text {
        color: white; }
    .page-gioi-thieu.section-3 .thumbnail-history-main .item-year .text {
      color: #111111;
      font-size: 1rem;
      font-weight: 700;
      transition: .3s all ease-in-out; }

.page-gioi-thieu .slide-main-history .swiper-container {
  padding-bottom: 20px; }

.page-gioi-thieu .wrap-content-history {
  display: flex;
  flex-wrap: wrap; }
  .page-gioi-thieu .wrap-content-history .img-main {
    flex: 0 0 59.7560%;
    max-width: 59.7560%;
    transition: .3s all ease-in-out; }
    @media (max-width: 767.98px) {
      .page-gioi-thieu .wrap-content-history .img-main {
        flex: 0 0 100%;
        max-width: 100%; } }
    .page-gioi-thieu .wrap-content-history .img-main:hover {
      box-shadow: 8px 8px 0px #14713d; }
    .page-gioi-thieu .wrap-content-history .img-main a {
      width: 100%;
      height: 420px; }
      .page-gioi-thieu .wrap-content-history .img-main a img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .page-gioi-thieu .wrap-content-history .content-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 40.244%;
    max-width: 40.244%;
    padding-left: 75px;
    padding-right: 100px;
    padding-bottom: 5.25rem; }
    @media (max-width: 1279.98px) {
      .page-gioi-thieu .wrap-content-history .content-main {
        padding: 30px;
        padding-bottom: 3.125rem; } }
    @media (max-width: 767.98px) {
      .page-gioi-thieu .wrap-content-history .content-main {
        flex: 0 0 100%;
        max-width: 100%; } }
    .page-gioi-thieu .wrap-content-history .content-main .title-year {
      color: #111111;
      font-size: 3rem;
      font-weight: 800;
      text-transform: uppercase;
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 30px; }
      .page-gioi-thieu .wrap-content-history .content-main .title-year::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 77px;
        height: 2px;
        background-color: #14713d; }

.page-gioi-thieu.section-4 {
  background-color: #f8f8f8; }
  @media (min-width: 1200px) {
    .page-gioi-thieu.section-4 {
      padding-top: 4.375rem;
      padding-bottom: 6.25rem; } }
  .page-gioi-thieu.section-4 .title-page {
    margin-bottom: 30px; }
  .page-gioi-thieu.section-4 .item-staff .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 104.91228%; }
    .page-gioi-thieu.section-4 .item-staff .img img, .page-gioi-thieu.section-4 .item-staff .img iframe, .page-gioi-thieu.section-4 .item-staff .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .page-gioi-thieu.section-4 .item-staff .title {
    color: #111111;
    font-size: 1rem;
    font-weight: 700;
    margin-top: 15px;
    text-transform: uppercase; }
  .page-gioi-thieu.section-4 .item-staff .position {
    color: #999999;
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 10px; }

.page-gioi-thieu.section-5 {
  background-color: #ffffff; }
  @media (min-width: 1200px) {
    .page-gioi-thieu.section-5 {
      padding-top: 4.375rem;
      padding-bottom: 4.8125rem; } }
  .page-gioi-thieu.section-5 .item-ccgt a {
    display: flex;
    width: 100%;
    height: 115px;
    align-items: center;
    justify-content: center;
    border: 1px solid #eeeeee;
    transition: .3s all ease-in-out; }
    .page-gioi-thieu.section-5 .item-ccgt a:hover {
      border: 1px solid #14713d; }
    .page-gioi-thieu.section-5 .item-ccgt a img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain; }
  .page-gioi-thieu.section-5 .item-ccgt .title {
    color: #111111;
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 19px;
    text-align: center; }
    .page-gioi-thieu.section-5 .item-ccgt .title:hover {
      color: #14713d; }

.page-lien-he {
  background-color: #f8f8f8;
  padding-top: 1.5rem; }
  .page-lien-he .title-page {
    margin-bottom: 19px; }
  .page-lien-he .wrap-box-flex {
    max-width: 936px;
    margin: 0 auto; }
    .page-lien-he .wrap-box-flex .swiper-container {
      padding: 10px; }
      @media (max-width: 575.98px) {
        .page-lien-he .wrap-box-flex .swiper-container {
          padding: 0; } }
    .page-lien-he .wrap-box-flex .row {
      margin-left: -10px;
      margin-right: -10px; }
      .page-lien-he .wrap-box-flex .row [class*='col'] {
        padding-left: 10px;
        padding-right: 10px; }
  .page-lien-he .item-box-lh {
    height: 175px;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 25px 30px 25px;
    transition: .3s all ease-in-out; }
    .page-lien-he .item-box-lh:hover {
      border: 1px solid #14713d;
      box-shadow: 4px 4px #14713d; }
    .page-lien-he .item-box-lh .icon {
      width: 45px;
      height: 45px;
      background-color: #14713d;
      background-image: linear-gradient(-145deg, #2ce718 0%, #14713d 100%);
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px; }
    .page-lien-he .item-box-lh .wrap-text {
      text-align: center; }
      @media (min-width: 1024px) {
        .page-lien-he .item-box-lh .wrap-text {
          padding: 0 1.25rem; } }
      .page-lien-he .item-box-lh .wrap-text strong {
        color: #111111;
        font-size: 12px;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 5px;
        display: block; }
      .page-lien-he .item-box-lh .wrap-text p {
        color: #111111;
        font-size: 14px; }
  .page-lien-he .wrap-social {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-top: 20px; }
    .page-lien-he .wrap-social li + li {
      margin-left: 10px; }
    .page-lien-he .wrap-social li a {
      width: 40px;
      height: 40px;
      border: 1px solid #e1e1e1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: .3s all ease-in-out; }
      .page-lien-he .wrap-social li a:hover {
        background-color: #14713d;
        border: 1px solid #14713d;
        box-shadow: 2px 2px 0px #0a552c; }
        .page-lien-he .wrap-social li a:hover em {
          color: white; }
      .page-lien-he .wrap-social li a em {
        color: #b0b0b0;
        transition: .3s all ease-in-out;
        font-size: 1.125rem;
        font-weight: 400; }

.wrap-map-form {
  background-color: #f8f8f8;
  padding-bottom: 5rem;
  padding-top: 2.5rem; }
  .wrap-map-form span.fa-exclamation-triangle {
    color: red;
    position: absolute;
    top: 19px;
    right: 15px;
    font-weight: 400 !important;
    font-style: italic;
    font-size: .9rem;
    animation: AlertError 0.4s 1 linear !important; }
  .wrap-map-form .wrap-flex-form {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 10px;
    box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.05); }
  .wrap-map-form .iframe-map {
    flex: 0 0 50.4065%;
    height: 630px; }
    @media (max-width: 1023.98px) {
      .wrap-map-form .iframe-map {
        flex: 0 0 100%;
        max-width: 100%;
        height: 50vh; } }
    @media (max-width: 767.98px) {
      .wrap-map-form .iframe-map {
        height: 30vh; } }
  .wrap-map-form textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #eeeeee rgba(238, 238, 238, 0.75);
    background-color: #ffffff;
    padding-left: 20px;
    color: #111111;
    padding-top: 15px; }
    .wrap-map-form textarea::placeholder {
      font-size: 14px;
      color: #999999; }
  .wrap-map-form .wrap-form-main {
    padding-top: 40px;
    flex: 0 0 49.5935%;
    padding-left: 70px;
    padding-right: 70px; }
    @media (max-width: 1023.98px) {
      .wrap-map-form .wrap-form-main {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 2.5rem 2.1875rem 4.375rem 2.1875rem; } }
    @media (max-width: 575.98px) {
      .wrap-map-form .wrap-form-main {
        padding: 2.5rem 0.9375rem 4.375rem 0.9375rem; } }
    .wrap-map-form .wrap-form-main .title {
      color: #111111;
      font-size: 0.875rem;
      font-weight: 400;
      margin-bottom: 20px;
      line-height: 1.7142; }
    .wrap-map-form .wrap-form-main .form-group {
      margin-bottom: 19px;
      position: relative; }
      .wrap-map-form .wrap-form-main .form-group input[type="text"] {
        width: 100%;
        height: 50px;
        border: 1px solid rgba(238, 238, 238, 0.75);
        background-color: #ffffff;
        font-size: 14px;
        color: #111111;
        padding-left: 20px; }
        .wrap-map-form .wrap-form-main .form-group input[type="text"]::placeholder {
          font-size: 14px;
          color: #999999; }
  .wrap-map-form .frm-btnwrap {
    display: flex;
    justify-content: flex-start; }
    .wrap-map-form .frm-btnwrap .frm-btn {
      position: relative; }
      .wrap-map-form .frm-btnwrap .frm-btn:hover input {
        background-color: #fff;
        border: 1px solid #14713d;
        color: #14713d;
        box-shadow: 4px 4px #14713d; }
      .wrap-map-form .frm-btnwrap .frm-btn:hover::before {
        color: #14713d; }
      .wrap-map-form .frm-btnwrap .frm-btn::before {
        content: "\EA6E";
        font-family: remixicon !important;
        color: #ffffff;
        font-size: 1.25rem;
        position: absolute;
        top: 50%;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        transition: .3s all ease-in-out; }
      .wrap-map-form .frm-btnwrap .frm-btn input {
        width: 170px;
        height: 45px;
        border-radius: 23px;
        background-color: #14713d;
        box-shadow: none;
        border: 0;
        text-align: center;
        color: #ffffff;
        font-size: 14px;
        transition: .3s all ease-in-out; }

.page-product-main .wrap-list-tab {
  border: 1px solid #eeeeee;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden; }
  .page-product-main .wrap-list-tab .item-tab {
    padding: 0 18px;
    display: flex;
    align-items: center;
    height: 130px;
    justify-content: center;
    border-right: 1px solid #eeeeee;
    flex-direction: column;
    transition: .3s all ease-in-out; }
    .page-product-main .wrap-list-tab .item-tab.active {
      background-color: #14713d; }
      .page-product-main .wrap-list-tab .item-tab.active .icon {
        filter: brightness(0) invert(1); }
      .page-product-main .wrap-list-tab .item-tab.active .text {
        color: white; }
    .page-product-main .wrap-list-tab .item-tab:hover {
      background-color: #14713d; }
      .page-product-main .wrap-list-tab .item-tab:hover .icon {
        filter: brightness(0) invert(1); }
      .page-product-main .wrap-list-tab .item-tab:hover .text {
        color: white; }
    .page-product-main .wrap-list-tab .item-tab .icon {
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px; }
    .page-product-main .wrap-list-tab .item-tab .text {
      color: #222222;
      font-size: 0.75rem;
      font-weight: 400;
      text-align: center; }

.page-product-main .wrap-filter-tab {
  display: flex;
  align-items: center;
  padding: 25px 20px;
  padding-bottom: 15px;
  border: 1px solid #eeeeee;
  margin-top: -1px; }
  @media (max-width: 767.98px) {
    .page-product-main .wrap-filter-tab {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 10px; } }
  .page-product-main .wrap-filter-tab .title {
    color: #111111;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    flex: 0 0 140px; }
    @media (max-width: 1023.98px) {
      .page-product-main .wrap-filter-tab .title {
        flex: 0 0 89px; } }
    @media (max-width: 767.98px) {
      .page-product-main .wrap-filter-tab .title {
        flex: 0 0 auto;
        margin-bottom: 10px; } }
  .page-product-main .wrap-filter-tab .list-tab {
    display: flex;
    align-items: center;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%; }
    .page-product-main .wrap-filter-tab .list-tab::-webkit-scrollbar {
      height: 8px; }
    .page-product-main .wrap-filter-tab .list-tab li {
      margin-right: 10px;
      margin-bottom: 10px;
      scroll-snap-align: start; }
      .page-product-main .wrap-filter-tab .list-tab li a {
        height: 40px;
        border-radius: 5px;
        border: 1px solid #14713d;
        background-color: #ffffff;
        color: #14713d;
        white-space: nowrap;
        padding: 0 1.375rem;
        font-size: 0.75rem;
        font-weight: 700;
        min-width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        transition: .3s all ease-in-out; }
        .page-product-main .wrap-filter-tab .list-tab li a.active {
          background-color: #14713d;
          color: white; }
        .page-product-main .wrap-filter-tab .list-tab li a:hover {
          background-color: #14713d;
          color: white; }

.product-list-main {
  padding-top: 2.625rem; }
  .product-list-main .item-product-main {
    margin-bottom: 30px; }
    .product-list-main .item-product-main .product-title-main {
      margin-top: 10px;
      display: block; }

.zone-tab-1 {
  position: relative;
  overflow-x: auto; }
  .zone-tab-1::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #e1e1e1; }
  .zone-tab-1 ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin: 0 auto;
    width: fit-content; }
    .zone-tab-1 ul li {
      display: flex;
      align-items: center; }
      .zone-tab-1 ul li + li::before {
        content: '|';
        padding: 0 2.5rem; }
        @media (max-width: 767.98px) {
          .zone-tab-1 ul li + li::before {
            padding: 0 0.9375rem; } }
      .zone-tab-1 ul li.active a {
        color: #14713d; }
        .zone-tab-1 ul li.active a::before {
          width: 56px; }
      .zone-tab-1 ul li a {
        color: #111111;
        font-size: 0.875rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        height: 49px;
        text-transform: uppercase;
        color: #111111;
        position: relative;
        white-space: nowrap;
        transition: .3s all  ease-in-out;
        text-align: center;
        padding-top: 3px; }
        @media (max-width: 575.98px) {
          .zone-tab-1 ul li a {
            font-size: 0.75rem; } }
        .zone-tab-1 ul li a:hover {
          color: #14713d; }
        .zone-tab-1 ul li a::before {
          content: '';
          width: 0;
          height: 3px;
          position: absolute;
          top: 91%;
          z-index: 10;
          left: 50%;
          transform: translate(-50%);
          background-color: #14713d;
          transition: .3s all  ease-in-out; }

@media (max-width: 575.98px) {
  .page-news-list {
    margin-bottom: 20px; } }

.page-news-list .title-page {
  margin-top: 25px;
  margin-bottom: 29px; }

@media (max-width: 1023.98px) {
  .page-news-list .row {
    margin-left: -5px;
    margin-right: -5px; }
    .page-news-list .row [class*='col'] {
      padding-left: 5px;
      padding-right: 5px; } }

.item-news-post.big {
  position: relative;
  margin-bottom: 30px; }
  @media (max-width: 575.98px) {
    .item-news-post.big {
      margin-bottom: 10px; } }
  .item-news-post.big .img a {
    display: block;
    width: 100%;
    height: 420px;
    position: relative; }
    @media (max-width: 1023.98px) {
      .item-news-post.big .img a {
        height: auto; } }
    .item-news-post.big .img a::before {
      content: '';
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0.01) 99%, rgba(0, 0, 0, 0) 100%);
      position: absolute;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2; }
    .item-news-post.big .img a img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .item-news-post.big .wrap-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    padding: 0px 20px 38px 30px; }
    @media (max-width: 767.98px) {
      .item-news-post.big .wrap-text {
        padding: 0 15px 15px 15px; } }

.item-news-post.small {
  height: 420px;
  margin-bottom: 30px; }
  @media (max-width: 1023.98px) {
    .item-news-post.small {
      height: auto; } }
  @media (max-width: 575.98px) {
    .item-news-post.small {
      margin-bottom: 10px; } }
  .item-news-post.small .title {
    color: #111111; }
  .item-news-post.small .date {
    color: #666666; }
  .item-news-post.small .content {
    color: #111111; }
  .item-news-post.small .img a {
    display: block;
    width: 100%;
    height: 220px; }
    @media (max-width: 1023.98px) {
      .item-news-post.small .img a {
        height: auto; } }
    .item-news-post.small .img a img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .item-news-post.small .wrap-text {
    height: 200px;
    padding: 24px 35px 30px 29px;
    background-color: #f8f8f8;
    transition: .3s all ease-in-out; }
    @media (max-width: 1023.98px) {
      .item-news-post.small .wrap-text {
        height: 175px; } }
    @media (max-width: 767.98px) {
      .item-news-post.small .wrap-text {
        padding: 15px;
        height: 150px; } }
    .item-news-post.small .wrap-text:hover {
      background-color: #14713d; }
      .item-news-post.small .wrap-text:hover .date {
        color: white; }
      .item-news-post.small .wrap-text:hover .title {
        color: white; }
      .item-news-post.small .wrap-text:hover .content {
        color: white; }

.item-news-post .date {
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 5px;
  transition: .3s all ease-in-out; }

.item-news-post .title {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 8px;
  display: block;
  transition: .3s all ease-in-out;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }
  @media (max-width: 767.98px) {
    .item-news-post .title {
      font-size: 0.875rem; } }
  .item-news-post .title:hover {
    text-decoration: underline; }

.item-news-post .content {
  color: #ffffff;
  transition: .3s all ease-in-out;
  font-size: 0.875rem;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

footer {
  background-color: #14713d;
  padding-top: 70px;
  padding-bottom: 40px; }
  @media (max-width: 1023.98px) {
    footer {
      padding-top: 3.125rem;
      padding-bottom: 1.875rem; } }
  footer .title-footer {
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 18px;
    text-transform: uppercase; }
  footer .desc-footer {
    opacity: 0.75;
    color: #ffffff;
    font-size: 0.875rem;
    font-weight: 300;
    margin-bottom: 16px; }
  @media (max-width: 1023.98px) {
    footer .col-lg-3 {
      margin-bottom: 30px; } }
  footer .col-lg-3:first-child .title-footer {
    margin-bottom: 25px; }
  footer .col-lg-3:first-child .list-footer li {
    font-weight: 300; }
  footer ul.list-footer {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    footer ul.list-footer li {
      color: white;
      opacity: .75;
      font-size: 0.875rem; }
      footer ul.list-footer li + li {
        margin-top: 11px; }
      footer ul.list-footer li a {
        color: white;
        opacity: .75;
        font-size: 0.875rem;
        padding-bottom: 5px;
        font-weight: 300;
        transition: .3s all ease-in-out !important;
        position: relative;
        transition: background-size .6s ease;
        background-image: linear-gradient(180deg, transparent 95%, white 0);
        background-repeat: no-repeat;
        background-size: 0 100%; }
        footer ul.list-footer li a:hover {
          background-size: 100% 100%; }
        footer ul.list-footer li a:hover {
          color: white;
          opacity: 1; }
  footer .wrap-list-social {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-top: 3.125rem; }
    @media (max-width: 1023.98px) {
      footer .wrap-list-social {
        margin-top: 1.875rem; } }
    footer .wrap-list-social li + li {
      margin-left: 10px; }
    footer .wrap-list-social li a {
      width: 40px;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 0.75);
      display: flex;
      align-items: center;
      border-radius: 50%;
      justify-content: center;
      transition: .3s all ease-in-out; }
      footer .wrap-list-social li a:hover {
        background-color: #fff; }
        footer .wrap-list-social li a:hover em {
          color: #14713d;
          opacity: 1; }
      footer .wrap-list-social li a em {
        color: white;
        opacity: .75;
        transition: .3s all ease-in-out; }
  footer .copyright-box {
    padding-top: 5rem;
    padding-bottom: 2.5rem; }
    @media (max-width: 1023.98px) {
      footer .copyright-box {
        padding-top: 0.625rem;
        padding-bottom: 1.875rem; } }
    footer .copyright-box .col-lg-6 {
      display: flex;
      align-items: center; }
      footer .copyright-box .col-lg-6 [class*='Module'] {
        width: 100%; }
    footer .copyright-box .copyright {
      display: flex;
      align-items: center; }
      footer .copyright-box .copyright .text {
        margin-left: 12px;
        opacity: 0.5;
        color: #ffffff;
        font-size: 0.875rem;
        font-weight: 400; }
    footer .copyright-box .list-footer {
      display: flex;
      align-items: center;
      margin-left: auto;
      width: fit-content; }
      @media (max-width: 1023.98px) {
        footer .copyright-box .list-footer {
          margin-top: 15px; } }
      footer .copyright-box .list-footer li {
        margin-top: 0; }
        footer .copyright-box .list-footer li + li {
          text-align: center; }
          footer .copyright-box .list-footer li + li::before {
            content: '|';
            padding: 0 4px; }

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 99;
  border-bottom: 1px solid #ebebeb; }
  header nav {
    position: relative;
    background-color: #fff;
    z-index: 100; }
    header nav .header-search {
      position: relative;
      cursor: pointer;
      max-width: 300px;
      width: 100%;
      height: 35px;
      border-radius: 18px;
      border: 1px solid #ebebeb;
      background-color: #f8f8f8;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 45px 0px 15px;
      transition: .3s all  ease-in-out; }
      header nav .header-search .Module, header nav .header-search .searchbox {
        width: 100%; }
      header nav .header-search.active {
        border: 1px solid #14713d; }
        header nav .header-search.active input {
          font-size: 0.875rem;
          pointer-events: auto; }
        header nav .header-search.active button {
          left: 92%;
          pointer-events: auto; }
      @media (max-width: 1024.98px) {
        header nav .header-search {
          max-width: 35px;
          height: 35px; } }
      header nav .header-search button {
        border: 0;
        outline: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: .3s all  ease-in-out;
        background-color: transparent;
        pointer-events: none; }
      header nav .header-search em {
        color: #14713d;
        font-size: 20px; }
      header nav .header-search input {
        border: 0;
        width: 100%;
        font-size: 0;
        background-color: #f8f8f8;
        pointer-events: none; }
    header nav .header-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 17px; }
      @media (max-width: 1024.98px) {
        header nav .header-top {
          display: none; } }
      header nav .header-top .header-location-phone {
        display: flex; }
        header nav .header-top .header-location-phone .phone {
          margin-right: 27px;
          margin-top: 4px; }
          header nav .header-top .header-location-phone .phone a {
            display: flex;
            align-items: center; }
          header nav .header-top .header-location-phone .phone span {
            color: #14713d;
            font-size: 1rem;
            font-weight: 600;
            text-transform: uppercase; }
          header nav .header-top .header-location-phone .phone em {
            color: #14713d;
            margin-right: 3px;
            font-size: 24px; }
        header nav .header-top .header-location-phone .language {
          display: flex;
          align-items: center; }
          header nav .header-top .header-location-phone .language a {
            width: 21px;
            height: 21px;
            display: block;
            border-radius: 50%; }
            header nav .header-top .header-location-phone .language a + a {
              margin-left: 10px; }
            header nav .header-top .header-location-phone .language a img {
              width: 100%;
              height: 100%; }
    header nav .header-bottom {
      display: flex;
      justify-content: center;
      height: 66px;
      padding-bottom: 4px; }
      header nav .header-bottom .logo {
        flex: 0 0 202px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative; }
        @media (max-width: 1024.98px) {
          header nav .header-bottom .logo [class*="Module"] {
            height: 100%; } }
        header nav .header-bottom .logo .img {
          position: absolute;
          top: -35px; }
          @media (max-width: 1024.98px) {
            header nav .header-bottom .logo .img {
              top: 0;
              height: 100%;
              padding: 5px 0px; }
              header nav .header-bottom .logo .img img {
                height: 100%;
                object-fit: contain;
                position: relative; } }
      header nav .header-bottom .list-menu-left, header nav .header-bottom .list-menu-right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100%; }
        header nav .header-bottom .list-menu-left [class*="Module"], header nav .header-bottom .list-menu-right [class*="Module"] {
          width: 100%; }
      header nav .header-bottom .list-menu-right {
        display: flex;
        justify-content: flex-end; }
      header nav .header-bottom ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        flex: 1;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        @media (max-width: 1023.98px) {
          header nav .header-bottom ul {
            display: none; } }
        header nav .header-bottom ul li.active a {
          color: #14713d; }
          header nav .header-bottom ul li.active a::before {
            width: 100%; }
        header nav .header-bottom ul li a {
          font-size: 0.9375rem;
          font-weight: 600;
          text-transform: uppercase;
          position: relative;
          padding-bottom: 5px;
          transition: .3s all ease-in-out; }
          header nav .header-bottom ul li a:hover {
            color: #14713d; }
            header nav .header-bottom ul li a:hover::before {
              width: 100%; }
          header nav .header-bottom ul li a::before {
            content: '';
            position: absolute;
            top: 100%;
            height: 1px;
            background-color: #14713d;
            width: 0%;
            transition: .3s all ease-in-out;
            left: 50%;
            transform: translateX(-50%); }
  header .header-mobile {
    position: fixed;
    top: 63px;
    left: 0;
    width: 300px;
    background-color: white;
    border-top: 1px solid #f1f1f1;
    height: 100vh;
    z-index: 99;
    transition: .3s all ease-in-out;
    transform: translateX(-100%);
    opacity: 0; }
    header .header-mobile.active {
      transform: translateX(0);
      opacity: 1; }
    header .header-mobile ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      header .header-mobile ul li.active a {
        background-color: #14713d;
        color: white; }
      header .header-mobile ul li a {
        display: block;
        padding: 14px 15px;
        border-top: 1px solid #f1f1f1;
        font-size: 1.125rem; }
    header .header-mobile .language {
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 15px; }
      header .header-mobile .language a + a {
        margin-left: 10px; }

.page-cong-trinh-detail .wrap-title-header {
  margin-top: 1.375rem; }
  .page-cong-trinh-detail .wrap-title-header .title {
    color: #14713d;
    font-size: 1.875rem;
    font-weight: 600;
    margin-top: 0.125rem; }
  .page-cong-trinh-detail .wrap-title-header .date-social {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .page-cong-trinh-detail .wrap-title-header .date-social .date {
      color: #666666;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 0.01px; }
    .page-cong-trinh-detail .wrap-title-header .date-social .social {
      width: 2.5rem;
      height: 2.5rem;
      border: 1px solid #e1e1e1;
      display: flex;
      align-items: center;
      border-radius: 50%;
      justify-content: center;
      padding-top: 3px;
      transition: .3s all ease-in-out; }
      .page-cong-trinh-detail .wrap-title-header .date-social .social:hover {
        background-color: #14713d;
        border: 1px solid #0a552c;
        box-shadow: 2px 2px 0px #0a552c; }
        .page-cong-trinh-detail .wrap-title-header .date-social .social:hover em {
          color: white; }
      .page-cong-trinh-detail .wrap-title-header .date-social .social em {
        font-size: 1.125rem;
        color: #999999;
        transition: .3s all ease-in-out; }

.page-cong-trinh-detail .wrap-main-product {
  margin-top: 1.875rem;
  margin-bottom: 4rem; }
  @media (max-width: 1023.98px) {
    .page-cong-trinh-detail .wrap-main-product {
      margin-bottom: 2rem; } }
  .page-cong-trinh-detail .wrap-main-product .wrap-main-img .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56.09756%; }
    .page-cong-trinh-detail .wrap-main-product .wrap-main-img .img a img, .page-cong-trinh-detail .wrap-main-product .wrap-main-img .img a iframe, .page-cong-trinh-detail .wrap-main-product .wrap-main-img .img a video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }

.page-cong-trinh-detail .wrap-main-thumb {
  max-width: 980px;
  margin: 0 auto;
  position: relative;
  margin-top: 20px; }
  .page-cong-trinh-detail .wrap-main-thumb .btn {
    top: 50% !important; }
  .page-cong-trinh-detail .wrap-main-thumb .swiper-slide-thumb-active .img {
    border: 2px solid #14713d; }
  .page-cong-trinh-detail .wrap-main-thumb .img {
    width: 100%;
    height: 101px;
    border: 2px solid transparent;
    transition: .3s all ease-in-out; }
    .page-cong-trinh-detail .wrap-main-thumb .img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.page-cong-trinh-detail .wrap-content-project {
  border-top: 3px solid #14713d;
  margin-top: 5rem; }
  @media (max-width: 1023.98px) {
    .page-cong-trinh-detail .wrap-content-project {
      margin-top: 3.125rem; } }
  .page-cong-trinh-detail .wrap-content-project table {
    width: 100%; }
    @media (max-width: 575.98px) {
      .page-cong-trinh-detail .wrap-content-project table tr {
        display: flex;
        flex-direction: column; } }
    .page-cong-trinh-detail .wrap-content-project table td {
      border-bottom: 1px solid #e1e1e1;
      padding: 17.5px 0px;
      text-transform: uppercase; }
      .page-cong-trinh-detail .wrap-content-project table td:first-child {
        font-weight: bold;
        padding-left: 6.4375rem;
        width: 31.5%;
        white-space: nowrap; }
        @media (max-width: 1023.98px) {
          .page-cong-trinh-detail .wrap-content-project table td:first-child {
            padding-left: 3.75rem; } }
        @media (max-width: 575.98px) {
          .page-cong-trinh-detail .wrap-content-project table td:first-child {
            width: 100%;
            padding-left: 1.25rem; } }
      .page-cong-trinh-detail .wrap-content-project table td:last-child {
        padding-left: 20px; }
        @media (max-width: 1023.98px) {
          .page-cong-trinh-detail .wrap-content-project table td:last-child {
            padding-right: 40px;
            padding-left: 40px; } }
        @media (max-width: 575.98px) {
          .page-cong-trinh-detail .wrap-content-project table td:last-child {
            padding-left: 20px; } }

.other-project-main {
  padding-bottom: 2.1875rem; }
  .other-project-main .item-cong-trinh .img:hover {
    box-shadow: none !important; }

.page-service-detail {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }
  @media (min-width: 1200px) {
    .page-service-detail {
      padding-top: 1.0625rem;
      padding-bottom: 1.25rem; } }
  .page-service-detail .title-page {
    text-transform: none;
    margin-bottom: 1.9375rem; }
  .page-service-detail .wrap-services-main .item-services {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3.75rem; }
    @media (min-width: 1024px) {
      .page-service-detail .wrap-services-main .item-services:nth-child(2n-1) .content-services {
        padding-left: 4.6875rem; }
      .page-service-detail .wrap-services-main .item-services:nth-child(2n) {
        flex-direction: row-reverse; }
        .page-service-detail .wrap-services-main .item-services:nth-child(2n) .content-services {
          padding-right: 4.6875rem; } }
  .page-service-detail .wrap-services-main .img-services {
    flex: 0 0 59.7560%;
    max-width: 59.7560%;
    transition: .3s all ease-in-out; }
    @media (max-width: 1023.98px) {
      .page-service-detail .wrap-services-main .img-services {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 30px; } }
    .page-service-detail .wrap-services-main .img-services:hover {
      box-shadow: 8px 8px 0px #14713d; }
    .page-service-detail .wrap-services-main .img-services img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .page-service-detail .wrap-services-main .content-services {
    flex: 0 0 40.244%;
    max-width: 40.244%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (max-width: 1023.98px) {
      .page-service-detail .wrap-services-main .content-services {
        flex: 0 0 100%;
        max-width: 100%; } }
    @media (max-width: 575.98px) {
      .page-service-detail .wrap-services-main .content-services * {
        text-align: justify; } }
    .page-service-detail .wrap-services-main .content-services .title-services {
      color: #333333;
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: 1.875rem; }
    .page-service-detail .wrap-services-main .content-services ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .page-service-detail .wrap-services-main .content-services ul li {
        display: flex;
        line-height: 2.25; }
        .page-service-detail .wrap-services-main .content-services ul li + li {
          margin-top: 0px; }
        .page-service-detail .wrap-services-main .content-services ul li::before {
          content: 'lens';
          color: #999999;
          font-family: "Material Icons";
          font-size: 8px;
          padding-top: 10px;
          margin-right: 20px; }

.other-services {
  background-color: #f8f8f8;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }
  @media (min-width: 1024px) {
    .other-services {
      padding-top: 4.25rem;
      padding-bottom: 4.3125rem; } }
  .other-services .title-page {
    margin-bottom: 1.8125rem; }
  .other-services .item-home-5 {
    height: 300px !important;
    margin-bottom: 30px; }
    @media (max-width: 1023.98px) {
      .other-services .item-home-5 {
        height: auto !important; } }
    @media (min-width: 1024px) {
      .other-services .item-home-5 .title {
        bottom: 32px;
        left: 39px; } }

.section-home-1 {
  padding-top: 4.6875rem;
  padding-bottom: 2.5rem; }
  @media (max-width: 767.98px) {
    .section-home-1 {
      padding-top: 1.875rem;
      padding-bottom: 1.875rem; } }
  .section-home-1 .title-main {
    margin-bottom: 45px; }
  .section-home-1 .wrap-slide-main {
    padding: 0 15px; }
  .section-home-1 .circle-product:hover .img::before {
    opacity: 1; }
  .section-home-1 .circle-product:hover .img em {
    transform: translate(-50%, -50%) scale(1); }
  .section-home-1 .circle-product .title-product {
    color: #333333;
    font-size: 0.875rem;
    font-weight: 600;
    margin-top: 30px;
    text-align: center;
    transition: .3s all  ease-in-out; }
    .section-home-1 .circle-product .title-product:hover {
      color: #14713d; }
  .section-home-1 .circle-product .img {
    background: rgba(15, 28, 63, 0.125);
    border-radius: 50%;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;
    border: 1px solid #ebebeb; }
    .section-home-1 .circle-product .img img {
      height: auto;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .section-home-1 .circle-product .img::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
      transition: .3s all  ease-in-out;
      background-color: rgba(0, 0, 0, 0.3); }
    .section-home-1 .circle-product .img em {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: .3s all  ease-in-out;
      color: #ffffff;
      font-size: 1.25rem;
      font-weight: 400;
      z-index: 3; }

.section-home-2 {
  margin-bottom: 150px;
  margin-top: 15px; }
  .section-home-2 .banner-home-img {
    position: relative; }
    .section-home-2 .banner-home-img > .img {
      height: 600px; }
      @media (max-width: 1279.98px) {
        .section-home-2 .banner-home-img > .img {
          height: 510px; } }
      @media (max-width: 1023.98px) {
        .section-home-2 .banner-home-img > .img {
          height: 500px; } }
      @media (max-width: 767.98px) {
        .section-home-2 .banner-home-img > .img {
          height: 400px; } }
      @media (max-width: 575.98px) {
        .section-home-2 .banner-home-img > .img {
          height: 427px; } }
      .section-home-2 .banner-home-img > .img img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center; }
  .section-home-2 .container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 19.5%;
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 767.98px) {
      .section-home-2 .container {
        top: 10.5%; } }
  .section-home-2 .text {
    display: flex;
    align-items: flex-start;
    max-width: 495px;
    flex-direction: column; }
    @media (max-width: 1023.98px) {
      .section-home-2 .text {
        max-width: 100%; } }
    .section-home-2 .text * {
      text-align: left; }
    .section-home-2 .text .home2-title-sub {
      color: #ffffff;
      font-size: 1.125rem;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 5px; }
      @media (max-width: 1023.98px) {
        .section-home-2 .text .home2-title-sub {
          margin-bottom: 10px; } }
    .section-home-2 .text .home2-title {
      color: #ffffff;
      font-size: 3rem;
      line-height: 1.145;
      font-weight: 400;
      margin-bottom: 40px; }
      @media (max-width: 767.98px) {
        .section-home-2 .text .home2-title {
          margin-bottom: 20px;
          font-size: 2.5rem; } }
    .section-home-2 .text .btn-main span {
      margin-right: 15px; }
  .section-home-2 .wrap {
    display: flex;
    flex-direction: column;
    max-width: 705px;
    width: 100%;
    align-items: flex-end; }
  .section-home-2 .wrap-slide-product {
    max-width: 705px;
    width: 100%;
    margin-top: 86px;
    position: relative;
    padding-left: 30px;
    padding-right: 75px; }
    @media (max-width: 1023.98px) {
      .section-home-2 .wrap-slide-product {
        padding-left: 0px; } }
    @media (max-width: 767.98px) {
      .section-home-2 .wrap-slide-product {
        margin-top: 60px; } }
  .section-home-2 .product-image {
    display: block;
    width: 100%; }
    .section-home-2 .product-image:hover .title {
      color: #14713d; }
    .section-home-2 .product-image .img {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .section-home-2 .product-image .img img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain; }
    .section-home-2 .product-image .title {
      color: #333333;
      font-size: 0.875rem;
      font-weight: 600;
      text-align: center;
      transition: .3s all ease-in-out;
      margin-top: 1.25rem;
      position: relative; }
      .section-home-2 .product-image .title:hover {
        color: #14713d; }
  .section-home-2 .btn-next-slide {
    width: 59px;
    height: 59px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    position: absolute;
    top: 46%;
    transform: translateY(-50%);
    right: 0;
    filter: none !important;
    transition: .3s all ease-in-out; }
    .section-home-2 .btn-next-slide:hover {
      box-shadow: 2px 2px #0a552c;
      background-color: #14713d; }
      .section-home-2 .btn-next-slide:hover img {
        filter: brightness(0) invert(1); }

.sec-product-main {
  padding: 3.125rem 0px; }
  @media (max-width: 1023.98px) {
    .sec-product-main {
      padding: 1.875rem 0px; } }
  .sec-product-main .title-main {
    margin-bottom: 33px; }
  .sec-product-main .btn.btn-sw-1 {
    top: 31% !important; }
  .sec-product-main .swiper-container {
    padding-bottom: 30px; }
    @media (max-width: 575.98px) {
      .sec-product-main .swiper-container {
        padding-bottom: 0px; } }
  .sec-product-main .btn-center {
    position: relative;
    margin-top: 20px; }
    .sec-product-main .btn-center::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #ebebeb;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1; }

.section-home-4 {
  min-height: 548px;
  background-position: fixed;
  display: flex;
  margin-top: 29px;
  align-items: center;
  justify-content: center; }
  @media (max-width: 575.98px) {
    .section-home-4 {
      padding-top: 5rem;
      padding-bottom: 2.5rem; } }
  .section-home-4 .title-main {
    text-transform: uppercase; }
  .section-home-4 .desc-main {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 30px;
    text-align: center; }
  .section-home-4 .wrap-box-center {
    max-width: 990px;
    margin: 0 auto; }
  .section-home-4 .wrap-box-circle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 909px;
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    flex-wrap: wrap; }
    .section-home-4 .wrap-box-circle .circle-item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex: 1 0 auto;
      margin-bottom: 30px; }
      .section-home-4 .wrap-box-circle .circle-item .text {
        color: white;
        margin-top: 1.875rem; }
        @media (max-width: 575.98px) {
          .section-home-4 .wrap-box-circle .circle-item .text {
            margin-top: 0.9375rem; } }
      .section-home-4 .wrap-box-circle .circle-item .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        border: 2px solid #ffffff;
        transition: .3s all ease-in-out; }
        .section-home-4 .wrap-box-circle .circle-item .circle:hover {
          background-color: #14713d;
          transform: scale(1.05); }

@media (min-width: 1200px) {
  .section-home-5 {
    padding-top: 4.375rem;
    padding-bottom: 3.9375rem; } }

.section-home-5 .title-main {
  text-transform: uppercase;
  margin-bottom: 30px; }

.section-home-5 .row {
  margin-left: -9.5px;
  margin-right: -9.5px; }
  .section-home-5 .row [class*='col'] {
    padding-left: 9.5px;
    padding-right: 9.5px; }
  @media (min-width: 1024px) {
    .section-home-5 .row .col-lg-8 {
      flex: 0 0 67.1737%;
      max-width: 67.1737%; } }
  .section-home-5 .row .col-lg-4 .wrap {
    height: 100%; }
    @media (max-width: 1023.98px) {
      .section-home-5 .row .col-lg-4 .wrap {
        display: flex;
        margin: 0 -15px; } }
  @media (max-width: 1023.98px) and (max-width: 767.98px) {
    .section-home-5 .row .col-lg-4 .wrap {
      margin: 0 -5px; } }
    @media (max-width: 1023.98px) {
        .section-home-5 .row .col-lg-4 .wrap .item-home-5 {
          flex: 0 0 50%;
          padding: 0 15px; } }
    @media (max-width: 1023.98px) and (max-width: 767.98px) {
      .section-home-5 .row .col-lg-4 .wrap .item-home-5 {
        padding: 0 5px; } }
  @media (min-width: 1024px) {
    .section-home-5 .row .col-lg-4 {
      flex: 0 0 32.8263%;
      max-width: 32.8263%; } }

.item-home-5 {
  position: relative; }
  .item-home-5.big .img {
    height: 400px; }
    @media (max-width: 1023.98px) {
      .item-home-5.big .img {
        height: auto;
        margin-bottom: 30px; } }
    @media (max-width: 767.98px) {
      .item-home-5.big .img {
        margin-bottom: 10px; } }
  .item-home-5.small {
    height: calc( 50% - 5px); }
    @media (max-width: 1023.98px) {
      .item-home-5.small {
        height: auto; } }
    @media (min-width: 1024px) {
      .item-home-5.small:first-child {
        margin-bottom: 10px; } }
  .item-home-5 .img {
    width: 100%;
    position: relative;
    display: block;
    height: 100%; }
    .item-home-5 .img img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .item-home-5 .img::before {
      content: '';
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
  .item-home-5 .title {
    color: #ffffff;
    font-size: 1.375rem;
    position: absolute;
    bottom: 20px;
    left: 30px;
    font-weight: 700;
    z-index: 3;
    text-transform: uppercase;
    transition: .3s all ease-in-out; }
    @media (max-width: 575.98px) {
      .item-home-5 .title {
        font-size: .9rem;
        left: 15px;
        bottom: 10px; } }

.section-product-main {
  padding-bottom: 4.6875rem; }
  @media (max-width: 1023.98px) {
    .section-product-main {
      padding-bottom: 1.875rem; } }
  .section-product-main .wrap-title-all {
    margin-bottom: 35px; }
  .section-product-main .row {
    margin-left: -13.5px;
    margin-right: -13.5px; }
  .section-product-main .project-img {
    position: relative;
    display: block;
    cursor: pointer;
    height: 100%;
    width: 100%; }
    .section-product-main .project-img img {
      width: 100%;
      object-fit: cover;
      height: 100%; }
  .section-product-main .wrap-left-img {
    padding: 0 13.5px;
    flex: 0 0 82.0206%;
    max-width: 82.0206%;
    height: 608px; }
    @media (max-width: 1279.98px) {
      .section-product-main .wrap-left-img {
        height: 495px; } }
    @media (max-width: 1023.98px) {
      .section-product-main .wrap-left-img {
        height: 355px;
        flex: 0 0 75%;
        max-width: 75%; } }
    @media (max-width: 767.98px) {
      .section-product-main .wrap-left-img {
        flex: 0 0 100%;
        max-width: 100%; } }
    .section-product-main .wrap-left-img .swiper-container {
      height: 100%; }
    .section-product-main .wrap-left-img .swiper-wrapper {
      height: 100%; }
    .section-product-main .wrap-left-img .img {
      height: calc( 100% - 43px); }
      .section-product-main .wrap-left-img .img img {
        height: 100%; }
    .section-product-main .wrap-left-img .title {
      color: #333333;
      font-size: 1.125rem;
      margin-top: 15px;
      padding-left: 4px;
      font-weight: 600;
      transition: .3s all ease-in-out;
      position: relative;
      display: inline-block; }
      .section-product-main .wrap-left-img .title::before {
        content: '';
        background-color: #f0f0f0;
        z-index: -1;
        position: absolute;
        height: 50%;
        width: 0%;
        transform: translate(-50%) scale(0.8);
        left: 50%;
        bottom: 2px;
        transition: .3s all ease-in-out; }
      .section-product-main .wrap-left-img .title:hover {
        color: #14713d; }
        .section-product-main .wrap-left-img .title:hover::before {
          width: 100%;
          transform: translate(-50%) scale(1.05); }
  .section-product-main .wrap-right-img {
    position: relative;
    padding: 0 13.5px;
    flex: 0 0 17.9794%;
    max-width: 17.9794%;
    height: 565px;
    max-height: 565px; }
    @media (max-width: 1279.98px) {
      .section-product-main .wrap-right-img {
        height: 452px; } }
    @media (max-width: 1023.98px) {
      .section-product-main .wrap-right-img {
        height: 312px;
        flex: 0 0 25%;
        max-width: 25%; } }
    @media (max-width: 767.98px) {
      .section-product-main .wrap-right-img {
        flex: 0 0 100%;
        max-width: 100%;
        height: auto;
        margin-top: 15px; } }
    .section-product-main .wrap-right-img::before {
      content: '';
      width: 30px;
      height: 124px;
      background-color: #43a141;
      position: absolute;
      top: 65px;
      left: -14.5px; }
      @media (max-width: 767.98px) {
        .section-product-main .wrap-right-img::before {
          display: none; } }
    .section-product-main .wrap-right-img .wrap-button-slide {
      font-size: 1.875rem; }
      @media (max-width: 1023.98px) {
        .section-product-main .wrap-right-img .wrap-button-slide {
          display: none; } }
      .section-product-main .wrap-right-img .wrap-button-slide .button-prev {
        position: absolute;
        bottom: 99%;
        user-select: none; }
        .section-product-main .wrap-right-img .wrap-button-slide .button-prev img {
          transition: .3s all ease-in-out; }
        .section-product-main .wrap-right-img .wrap-button-slide .button-prev:hover img {
          transform: scale(1.1); }
      .section-product-main .wrap-right-img .wrap-button-slide .button-next {
        position: absolute;
        user-select: none;
        top: 99%; }
        .section-product-main .wrap-right-img .wrap-button-slide .button-next img {
          transition: .3s all ease-in-out; }
        .section-product-main .wrap-right-img .wrap-button-slide .button-next:hover img {
          transform: scale(1.05); }
    .section-product-main .wrap-right-img .swiper-container {
      height: 100%; }
    .section-product-main .wrap-right-img .swiper-wrapper {
      height: 100%; }
      .section-product-main .wrap-right-img .swiper-wrapper .swiper-slide .project-img {
        overflow: hidden; }
      .section-product-main .wrap-right-img .swiper-wrapper .swiper-slide:hover img {
        filter: grayscale(0%); }
      .section-product-main .wrap-right-img .swiper-wrapper .swiper-slide img {
        filter: grayscale(100%);
        transition: .3s all ease-in-out; }
      .section-product-main .wrap-right-img .swiper-wrapper .swiper-slide.swiper-slide-thumb-active img {
        filter: grayscale(0%); }

.section-home-7 {
  padding-bottom: 2.5rem; }
  .section-home-7 .title-main {
    margin-bottom: 30px;
    text-transform: uppercase; }
  @media (max-width: 1023.98px) {
    .section-home-7 .row {
      flex-direction: column-reverse; } }
  .section-home-7 .home-7-post-list {
    padding-right: 2.8125rem; }
    @media (max-width: 1199.98px) {
      .section-home-7 .home-7-post-list {
        padding-right: 0px; } }
    .section-home-7 .home-7-post-list .home-7-post-item {
      display: flex;
      transition: .3s all ease-in-out; }
      .section-home-7 .home-7-post-list .home-7-post-item:hover {
        box-shadow: 2px 2px 5px rgba(20, 113, 61, 0.3); }
      .section-home-7 .home-7-post-list .home-7-post-item + .home-7-post-item {
        margin-top: 10px; }
      .section-home-7 .home-7-post-list .home-7-post-item .img {
        flex: 0 0 210px;
        max-width: 210px;
        height: 140px; }
        @media (max-width: 575.98px) {
          .section-home-7 .home-7-post-list .home-7-post-item .img {
            flex: 0 0 155px;
            max-width: 155px;
            height: 110px; } }
        .section-home-7 .home-7-post-list .home-7-post-item .img a {
          display: block;
          width: 100%;
          height: 100%; }
          .section-home-7 .home-7-post-list .home-7-post-item .img a img {
            width: 100%;
            height: 100%;
            object-fit: cover; }
      .section-home-7 .home-7-post-list .home-7-post-item .content {
        height: 140px;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 100%;
        align-items: flex-start;
        padding-left: 30px;
        justify-content: center; }
        @media (max-width: 575.98px) {
          .section-home-7 .home-7-post-list .home-7-post-item .content {
            height: 110px; } }
        .section-home-7 .home-7-post-list .home-7-post-item .content .title {
          color: #14713d;
          font-size: 1.25rem;
          font-weight: 700;
          margin-bottom: 11px;
          text-transform: uppercase; }
  .section-home-7 .wrap-video-main {
    position: relative;
    display: block;
    width: 100%;
    transition: .3s all ease-in-out; }
    @media (max-width: 1023.98px) {
      .section-home-7 .wrap-video-main {
        margin-bottom: 30px; } }
    .section-home-7 .wrap-video-main:hover {
      box-shadow: 8px 8px #14713d; }
    .section-home-7 .wrap-video-main .img {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 63.50626%;
      padding-top: 59.50626%; }
      .section-home-7 .wrap-video-main .img img, .section-home-7 .wrap-video-main .img iframe, .section-home-7 .wrap-video-main .img video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
    .section-home-7 .wrap-video-main .button-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 56px;
      height: 56px;
      background-color: #14713d;
      opacity: 0.9;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s all ease-in-out; }
      .section-home-7 .wrap-video-main .button-play:hover {
        box-shadow: 4px 4px #0a552c; }

.section-home-8 {
  padding-top: 0; }
  @media (min-width: 1200px) {
    .section-home-8 {
      padding-top: 2.8125rem;
      padding-bottom: 4.375rem; } }
  .section-home-8 [class*='Module'] {
    width: 100%; }
  .section-home-8 span.fa-exclamation-triangle {
    position: absolute;
    top: 105%;
    left: 0;
    color: red;
    font-weight: 400 !important;
    font-style: italic;
    width: fit-content !important;
    font-size: .9rem;
    animation: AlertError 0.4s 1 linear !important; }
  .section-home-8 .title {
    color: #14713d;
    font-size: 2.25rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.166;
    max-width: 720px; }
  .section-home-8 .desc-main {
    color: #333333;
    font-size: 1rem;
    text-align: center;
    line-height: 1.5;
    max-width: 860px;
    margin-top: 30px; }
  .section-home-8 .container .ModuleContent {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .section-home-8 .subscribe {
    position: relative;
    margin: 0 auto;
    margin-top: 30px;
    width: 100%;
    max-width: 600px; }
    .section-home-8 .subscribe .subscribethanks {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 600;
      color: #14713d; }
    .section-home-8 .subscribe button {
      width: 200px;
      height: 40px;
      border-radius: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      justify-content: center;
      color: white;
      background-color: #14713d;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 500;
      transition: .3s all ease-in-out;
      text-transform: uppercase; }
      @media (max-width: 575.98px) {
        .section-home-8 .subscribe button {
          width: 150px; } }
      .section-home-8 .subscribe button:hover {
        background-color: #0a552c; }
    .section-home-8 .subscribe input {
      width: 100%;
      color: #999999;
      font-size: 0.875rem;
      font-weight: 400;
      text-align: center;
      border: 0;
      outline: none;
      display: flex;
      align-items: center;
      padding: 5px 6px;
      height: 50px;
      border-radius: 25px;
      border: 1px solid #ebebeb;
      display: flex;
      justify-content: space-between;
      padding-right: 200px;
      transition: .3s all ease-in-out; }
      .section-home-8 .subscribe input:hover, .section-home-8 .subscribe input:focus {
        border: 1px solid #14713d;
        box-shadow: 2px 2px #14713d; }

.product-main-detail {
  padding-top: 30px; }
  .product-main-detail .title-page {
    font-weight: 500;
    margin-bottom: 22px; }
  .product-main-detail .btn-center {
    margin-top: 29px; }
  .product-main-detail .wrap-810 {
    max-width: 810px;
    margin: 0 auto; }
    .product-main-detail .wrap-810 p {
      text-align: center; }
    .product-main-detail .wrap-810 table {
      margin-top: 25px;
      width: 100%;
      border-top: 2px solid #14713d; }
      .product-main-detail .wrap-810 table tr td {
        padding: 14px 0px;
        border-bottom: 1px solid #e1e1e1; }
        .product-main-detail .wrap-810 table tr td:first-child {
          background-color: #f8f8f8;
          color: #111111;
          font-size: 14px;
          font-weight: 700;
          padding-left: 30px;
          width: 230px; }
          @media (max-width: 575.98px) {
            .product-main-detail .wrap-810 table tr td:first-child {
              width: 50%;
              padding-left: 15px; } }
        .product-main-detail .wrap-810 table tr td:last-child {
          padding-left: 7px;
          color: #111111;
          font-size: 14px;
          font-weight: 400; }
          @media (max-width: 575.98px) {
            .product-main-detail .wrap-810 table tr td:last-child {
              width: 50%;
              padding-left: 15px; } }
  .product-main-detail .product-content-detail {
    margin-bottom: 30px; }
  .product-main-detail .wrap-gallery-image {
    margin-bottom: 6.25rem; }
    @media (max-width: 1023.98px) {
      .product-main-detail .wrap-gallery-image {
        margin-bottom: 2.5rem; } }
    .product-main-detail .wrap-gallery-image .row {
      margin-left: -5px;
      margin-right: -5px; }
      .product-main-detail .wrap-gallery-image .row [class*='col'] {
        padding-left: 5px;
        padding-right: 5px; }
    .product-main-detail .wrap-gallery-image .item-gallery {
      margin-bottom: 10px; }
      .product-main-detail .wrap-gallery-image .item-gallery.small a {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 57.56824%; }
        .product-main-detail .wrap-gallery-image .item-gallery.small a img, .product-main-detail .wrap-gallery-image .item-gallery.small a iframe, .product-main-detail .wrap-gallery-image .item-gallery.small a video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }
      .product-main-detail .wrap-gallery-image .item-gallery a {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 57.37705%; }
        .product-main-detail .wrap-gallery-image .item-gallery a img, .product-main-detail .wrap-gallery-image .item-gallery a iframe, .product-main-detail .wrap-gallery-image .item-gallery a video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }

.wrap-main-3d {
  background-color: #f8f8f8;
  padding-top: 1.875rem;
  padding-bottom: 5rem; }
  @media (max-width: 1023.98px) {
    .wrap-main-3d {
      padding-bottom: 1.875rem; } }
  .wrap-main-3d .title-image-3d {
    color: #111111;
    font-size: 1.875rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px; }
  .wrap-main-3d .wrap-img-3d {
    height: 0;
    overflow: hidden;
    padding-top: calc( 690 / 1230 * 100%);
    background-color: white;
    position: relative;
    margin-bottom: 30px; }
    .wrap-main-3d .wrap-img-3d .icon {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 9; }
    .wrap-main-3d .wrap-img-3d .img-3d {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.product-detail-infomation {
  position: relative; }
  .product-detail-infomation .file-download {
    margin-top: 1.25rem; }
    .product-detail-infomation .file-download .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-left: 15px;
      border: 1px solid #e1e1e1;
      transition: .3s all ease-in-out;
      margin-left: 1.25rem; }
      .product-detail-infomation .file-download .icon em {
        transition: .3s all ease-in-out; }
      .product-detail-infomation .file-download .icon:hover {
        background-color: #14713d; }
        .product-detail-infomation .file-download .icon:hover em {
          color: white; }
  .product-detail-infomation .container {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%); }
  .product-detail-infomation .row {
    height: 100%; }
  .product-detail-infomation .col-lg-6 {
    height: 100%; }
  .product-detail-infomation .img img {
    height: 100%;
    width: 100%;
    object-fit: cover; }
  @media (max-width: 1023.98px) {
    .product-detail-infomation .img {
      height: 350px; } }
  .product-detail-infomation .box-info-product {
    background-color: rgba(255, 255, 255, 0.9);
    height: 100%;
    padding: 48px 19px 30px 38px; }
    @media (max-width: 1279.98px) {
      .product-detail-infomation .box-info-product {
        padding-top: 20px; } }
    .product-detail-infomation .box-info-product .share-social {
      display: flex;
      align-items: center;
      margin-top: 19px; }
      .product-detail-infomation .box-info-product .share-social p {
        color: #111111;
        font-size: 14px;
        font-weight: 500;
        line-height: 0.01px; }
      .product-detail-infomation .box-info-product .share-social a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: 15px;
        border: 1px solid #e1e1e1;
        transition: .3s all ease-in-out; }
        .product-detail-infomation .box-info-product .share-social a em {
          transition: .3s all ease-in-out; }
        .product-detail-infomation .box-info-product .share-social a:hover {
          background-color: #14713d; }
          .product-detail-infomation .box-info-product .share-social a:hover em {
            color: white; }
    .product-detail-infomation .box-info-product .content {
      max-height: 400px;
      padding-right: 40px;
      overflow: auto; }
      @media (max-width: 1700px) {
        .product-detail-infomation .box-info-product .content {
          max-height: 76%; } }
      @media (max-width: 1279.98px) {
        .product-detail-infomation .box-info-product .content {
          max-height: calc( 100% - 80px); } }
      .product-detail-infomation .box-info-product .content::-webkit-scrollbar {
        width: 3; }
      .product-detail-infomation .box-info-product .content::-webkit-scrollbar-track {
        background: #d1d1d1; }
      .product-detail-infomation .box-info-product .content::-webkit-scrollbar-thumb {
        background: #14713d; }
      .product-detail-infomation .box-info-product .content table {
        margin-top: 0.9375rem; }
        .product-detail-infomation .box-info-product .content table tr td {
          border: 1px solid #e0e0e0;
          padding: 5px; }
    .product-detail-infomation .box-info-product .title {
      color: #111111;
      font-size: 1.875rem;
      font-weight: 500;
      margin-bottom: 5px; }
    .product-detail-infomation .box-info-product ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .product-detail-infomation .box-info-product ul li {
        color: #111111;
        font-size: 15px;
        font-weight: 400;
        display: flex;
        line-height: 2.4; }
        @media (max-width: 575.98px) {
          .product-detail-infomation .box-info-product ul li {
            line-height: 1.8; } }
        .product-detail-infomation .box-info-product ul li::before {
          content: 'lens';
          color: #999999;
          font-family: "Material Icons";
          font-size: 8px;
          padding-top: 10px;
          margin-right: 16px; }

.sec-product-main {
  padding-top: 4.25rem; }
  @media (max-width: 1023.98px) {
    .sec-product-main {
      padding-top: 1.875rem; } }
  .sec-product-main .title-main {
    margin-bottom: 30px; }

.other-product-detail .product-title-main {
  text-transform: uppercase;
  margin-top: 15px;
  display: block; }

#modal-popup-phone {
  position: relative;
  overflow: visible; }
  #modal-popup-phone .icon-circle {
    width: 70px;
    height: 70px;
    background-color: #14713d;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center; }
    #modal-popup-phone .icon-circle em {
      color: white;
      font-size: 2.375rem; }
  #modal-popup-phone p {
    color: #14713d;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600; }
  #modal-popup-phone .wrap-content {
    margin-top: 20px; }
  #modal-popup-phone .fancybox-close-small {
    background-color: #14713d;
    color: white;
    opacity: 1;
    padding: 5px; }

.product-detail-page .fancybox-is-open .fancybox-bg {
  opacity: .3 !important; }

.page-news-detail {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 3.4375rem;
  margin-bottom: 1.875rem; }
  @media (max-width: 1023.98px) {
    .page-news-detail {
      padding-bottom: 1.875rem; } }
  .page-news-detail .wrap-title-header {
    margin-top: 1.375rem; }
    .page-news-detail .wrap-title-header .title {
      color: #14713d;
      font-size: 1.875rem;
      font-weight: 600;
      margin-top: 0.125rem; }
    .page-news-detail .wrap-title-header .date-social {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .page-news-detail .wrap-title-header .date-social .date {
        color: #666666;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 0.01px; }
      .page-news-detail .wrap-title-header .date-social .social {
        width: 2.5rem;
        height: 2.5rem;
        border: 1px solid #e1e1e1;
        display: flex;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        padding-top: 3px;
        transition: .3s all ease-in-out; }
        .page-news-detail .wrap-title-header .date-social .social:hover {
          background-color: #14713d;
          border: 1px solid #0a552c;
          box-shadow: 2px 2px 0px #0a552c; }
          .page-news-detail .wrap-title-header .date-social .social:hover em {
            color: white; }
        .page-news-detail .wrap-title-header .date-social .social em {
          font-size: 1.125rem;
          color: #999999;
          transition: .3s all ease-in-out; }
  .page-news-detail .content-main-post h2, .page-news-detail .content-main-post h3 {
    color: #111111;
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.6875rem; }
  .page-news-detail .content-main-post p {
    margin-bottom: 1.375rem; }
    @media (max-width: 575.98px) {
      .page-news-detail .content-main-post p {
        text-align: justify; } }
  .page-news-detail .content-main-post img {
    margin-top: 1.875rem;
    width: 100%;
    margin-bottom: 1.875rem; }

.padding-blog, .page-cong-trinh-detail .wrap-title-header, .page-news-detail .wrap-title-header, .page-news-detail .content-main-post h2, .page-news-detail .content-main-post h3, .page-news-detail .content-main-post p {
  padding: 0 6.4375rem; }
  @media (max-width: 1600px) {
    .padding-blog, .page-cong-trinh-detail .wrap-title-header, .page-news-detail .wrap-title-header, .page-news-detail .content-main-post h2, .page-news-detail .content-main-post h3, .page-news-detail .content-main-post p {
      padding: 0 3.125rem; } }
  @media (max-width: 767.98px) {
    .padding-blog, .page-cong-trinh-detail .wrap-title-header, .page-news-detail .wrap-title-header, .page-news-detail .content-main-post h2, .page-news-detail .content-main-post h3, .page-news-detail .content-main-post p {
      padding: 0 1.25rem; } }

.post-other-main {
  margin-bottom: 30px; }

@media (max-width: 768.67px) {
  .number-tab-wrap {
    display: flex; }
    .number-tab-wrap .tabslet-tab {
      flex: 1; }
    .number-tab-wrap .icon-next {
      flex: 0 0 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      border: 1px solid #14713d; }
      .number-tab-wrap .icon-next .next {
        margin-top: 5px; }
      .number-tab-wrap .icon-next em {
        color: #14713d; } }

.wrap-search-main {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px 0px;
  padding-bottom: 15px;
  position: fixed;
  width: 100%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: .4s all ease-in-out;
  z-index: 99;
  box-shadow: 1px 5px 15px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  height: 100%; }
  .wrap-search-main.active {
    opacity: 1;
    visibility: visible; }
    .wrap-search-main.active .close {
      top: 5px;
      opacity: 1; }
    .wrap-search-main.active .searchbox button em {
      transform: translateY(0px);
      opacity: 1;
      transition-delay: .5s; }
    .wrap-search-main.active .searchbox input {
      transform: translateX(0px);
      opacity: 1; }
    .wrap-search-main.active .searchbox::before {
      width: 100%;
      opacity: 1; }
  .wrap-search-main .close {
    position: absolute;
    top: 0px;
    right: 20px;
    display: flex;
    cursor: pointer;
    opacity: 0;
    transition: .3s all ease-in-out;
    transition-delay: .4s; }
    .wrap-search-main .close em {
      color: white;
      font-size: 40px; }
  .wrap-search-main .searchbox {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 5px;
    width: 100%; }
    .wrap-search-main .searchbox [class*="Module"] {
      width: 100%; }
    .wrap-search-main .searchbox.focus::after {
      opacity: 1;
      width: 100%; }
    .wrap-search-main .searchbox.notFocus::after {
      opacity: 1;
      right: 0;
      left: auto;
      width: 0%; }
    .wrap-search-main .searchbox::before {
      content: '';
      width: 0%;
      opacity: 1;
      height: 1px;
      position: absolute;
      left: 0;
      top: calc( 100% + 5px);
      background-color: #fff;
      transition-delay: .1s;
      transition: .75s all ease-in-out; }
    .wrap-search-main .searchbox::after {
      content: '';
      width: 0;
      opacity: 0;
      height: 1px;
      position: absolute;
      left: 0;
      top: calc( 100% + 5px);
      background-color: #14713d;
      transition-delay: .1s;
      transition: .5s all ease-in-out; }
    .wrap-search-main .searchbox input {
      flex: 1;
      border: 0;
      background-color: transparent;
      color: white;
      transition: .6s all ease-in-out;
      transition-delay: .2s;
      transform: translateX(-30px);
      opacity: 0; }
      .wrap-search-main .searchbox input::placeholder {
        color: white; }
    .wrap-search-main .searchbox button {
      outline: none;
      border: 0;
      background-color: transparent;
      display: flex;
      padding: 0; }
      .wrap-search-main .searchbox button em {
        color: white;
        opacity: 0;
        transform: translateY(-20px);
        transition: .3s all ease-in-out; }
