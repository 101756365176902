.section-home-5
	@media (min-width: 1200px)
		padding-top: r(70px)
		padding-bottom: r(63px)
	.title-main
		text-transform: uppercase
		margin-bottom: 30px
	.row
		margin-left: -9.5px
		margin-right: -9.5px
		[class*='col']
			padding-left: 9.5px
			padding-right: 9.5px
		.col-lg-8
			@media (min-width: 1024px)
				flex: 0 0 67.1737%
				max-width: 67.1737%
		.col-lg-4
			.wrap
				height: 100%
				@media (max-width: 1023.98px)
					display: flex
					margin: 0 -15px
					@media (max-width: 767.98px)
						margin: 0 -5px
					.item-home-5
						flex: 0 0 50%
						padding: 0 15px
						@media (max-width: 767.98px)
							padding: 0 5px
			@media (min-width: 1024px)
				flex: 0 0 32.8263%
				max-width: 32.8263%
.item-home-5
	position: relative
	&.big
		.img
			height: 400px
			@media (max-width: 1023.98px)
				height: auto
				margin-bottom: 30px
			@media (max-width: 767.98px)
				margin-bottom: 10px
	&.small
		height: calc( 50% - 5px )
		@media (max-width: 1023.98px)
			height: auto
		&:first-child
			@media (min-width: 1024px)
				margin-bottom: 10px
	.img
		width: 100%
		position: relative
		display: block
		height: 100%
		img
			height: 100%
			width: 100%
			object-fit: cover
		&::before
			content: ''
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)
			width: 100%
			height: 100%
			position: absolute
			top: 0
			left: 0
			z-index: 2
	.title
		color: #ffffff
		font-size: r(22px)
		position: absolute
		bottom: 20px
		left: 30px
		font-weight: 700
		z-index: 3
		text-transform: uppercase
		transition: .3s all ease-in-out
		@media (max-width: 575.98px)
			font-size:  .9rem
			left: 15px
			bottom: 10px


