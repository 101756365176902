.section-home-8
	padding-top: 0
	@media (min-width: 1200px)
		padding-top: r(45px)
		padding-bottom: r(70px)
	[class*='Module']
		width: 100%
	span.fa-exclamation-triangle
		position: absolute
		top: 105%
		left: 0
		color: red
		font-weight: 400!important
		font-style: italic
		width: fit-content !important
		font-size: .9rem
		animation: AlertError 0.4s 1 linear!important
	.title
		color: $main
		font-size: r(36px)
		font-weight: 300
		text-align: center
		line-height: 1.166
		max-width: 720px
	.desc-main
		color: #333333
		font-size: 1rem
		text-align: center
		line-height: 1.5
		max-width: 860px
		margin-top: 30px
	.container
		.ModuleContent
			margin: 0 auto
			display: flex
			flex-direction: column
			align-items: center
	.subscribe
		position: relative
		margin: 0 auto
		margin-top: 30px
		width: 100%
		max-width: 600px
		.subscribethanks
			text-align: center
			font-size: r(24px)
			font-weight: 600
			color: $main
		button
			width: 200px
			height: 40px
			border-radius: 20px
			position: absolute
			top: 50%
			transform: translateY(-50%)
			right: 10px
			display: flex
			align-items: center
			border: none
			outline: none
			justify-content: center
			color: white
			background-color: $main
			color: #ffffff
			font-size: r(14px)
			font-weight: 500
			transition: .3s all ease-in-out
			text-transform: uppercase
			@media (max-width: 575.98px)
				width: 150px
			&:hover
				background-color: $green-hover
		input
			width: 100%
			color: #999999
			font-size: r(14px)
			font-weight: 400
			text-align: center
			border: 0
			outline: none
			display: flex
			align-items: center
			padding: 5px 6px
			height: 50px
			border-radius: 25px
			border: 1px solid #ebebeb
			display: flex
			justify-content: space-between
			padding-right: 200px
			transition: .3s all ease-in-out
			&:hover,&:focus
				border: 1px solid $main
				box-shadow: 2px 2px $main

