.global-breadcrumb
	width: 100%
	background-color: white
	.breadcrumb
		display: flex
		align-items: center
		background-color: transparent
		padding: 15px 0
		list-style: none
		padding-bottom: 10px
		margin-bottom: 0
		overflow: auto
		&::-webkit-scrollbar
			height: 0px
		li
			position: relative
			display: flex
			align-items: center
			a
				font-size: 12px
				color: #999999
				font-weight: 500
				white-space: nowrap
				text-decoration: none
				@media(max-width: 991.98px)
					font-size: 10px
			&:hover
				a
					color: $main
			&:first-child
				padding-left: 0
		li+li
			&::before
				content: '/'
				color: #999
				font-size: 12px
				padding-right: 2px

	@media(min-width: 768.98px)
		margin-bottom: 0

	@media(min-width: 320px)
		margin-bottom: 0
