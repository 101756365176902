.page-news-detail
	border-bottom: 1px solid $line
	padding-bottom: r(55px)
	margin-bottom: r(30px)
	@media (max-width: 1023.98px)
		padding-bottom: r(30px)
	.wrap-title-header
		@extend .padding-blog
		margin-top: r(22px)
		.title
			color: $main
			font-size: r(30px)
			font-weight: 600
			margin-top: r(2px)
		.date-social
			display: flex
			align-items: center
			justify-content: space-between
			.date
				color: $gray-2
				font-size: r(14px)
				font-weight: 400
				line-height: 0.01px
			.social
				width: r(40px)
				height: r(40px)
				border: 1px solid $line
				display: flex
				align-items: center
				border-radius: 50%
				justify-content: center
				padding-top: 3px
				transition: .3s all ease-in-out
				&:hover
					background-color: $main
					border: 1px solid $green-hover
					box-shadow: 2px 2px 0px $green-hover
					em
						color: white
				em
					font-size: r(18px)
					color: #999999
					transition: .3s all ease-in-out
	.content-main-post
		h2,h3
			color: #111111
			font-size: r(20px)
			font-weight: 700
			text-transform: uppercase
			margin-bottom: r(27px)
			@extend .padding-blog
		p
			margin-bottom: r(22px)
			@extend .padding-blog
			@media (max-width: 575.98px)
				text-align: justify
		img
			margin-top: r(30px)
			width: 100%
			margin-bottom: r(30px)
.padding-blog
	padding: 0 r(103px)
	@media (max-width: 1600px)
		padding: 0 r(50px)
	@media (max-width: 767.98px)
		padding: 0 r(20px)
.post-other-main
	margin-bottom: 30px
