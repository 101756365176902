.page-product-main
	.wrap-list-tab
		border: 1px solid #eeeeee
		border-radius: 10px 10px 0px 0px
		overflow: hidden
		.item-tab
			padding: 0 18px
			display: flex
			align-items: center
			height: 130px
			justify-content: center
			border-right: 1px solid #eeeeee
			flex-direction: column
			transition: .3s all ease-in-out
			&.active
				background-color: $main
				.icon
					filter: brightness(0) invert(1)
				.text
					color: white
			&:hover
				background-color: $main
				.icon
					filter: brightness(0) invert(1)
				.text
					color: white
			.icon
				height: 49px
				display: flex
				align-items: center
				justify-content: center
				margin-bottom: 15px
			.text
				color: #222222
				font-size: r(12px)
				font-weight: 400
				text-align: center
	.wrap-filter-tab
		display: flex
		align-items: center
		padding: 25px 20px
		padding-bottom: 15px
		border: 1px solid #eeeeee
		margin-top: -1px
		@media (max-width: 767.98px)
			flex-direction: column
			align-items: flex-start
			padding-top: 10px
		.title
			color: $black
			font-size: r(14px)
			font-weight: 600
			text-transform: uppercase
			flex: 0 0 140px
			@media (max-width: 1023.98px)
				flex: 0 0 89px
			@media (max-width: 767.98px)
				flex: 0 0 auto
				margin-bottom: 10px
		.list-tab
			display: flex
			align-items: center
			overflow-x: auto
			scroll-snap-type: x mandatory
			+type-none()
			width: 100%
			&::-webkit-scrollbar
				height: 8px
			li
				margin-right: 10px
				margin-bottom: 10px
				scroll-snap-align: start
				a
					height: 40px
					border-radius: 5px
					border: 1px solid $main
					background-color: #ffffff
					color: $main
					white-space: nowrap
					padding: 0 r(22px)
					font-size: r(12px)
					font-weight: 700
					min-width: 90px
					display: flex
					align-items: center
					justify-content: center
					text-transform: uppercase
					transition: .3s all ease-in-out
					&.active
						background-color: $main
						color: white
					&:hover
						background-color: $main
						color: white
.product-list-main
	padding-top: r(42px)
	.item-product-main
		margin-bottom: 30px
		.product-title-main
			margin-top: 10px
			display: block
