.sec-product-main
	padding: r(50px) 0px
	@media (max-width: 1023.98px)
		padding: r(30px) 0px
	.title-main
		margin-bottom: 33px
	.btn.btn-sw-1
		top: 31%!important
	.swiper-container
		padding-bottom: 30px
		@media (max-width: 575.98px)
			padding-bottom: 0px
	.btn-center
		position: relative
		margin-top: 20px
		&::before
			content: ''
			width: 100%
			height: 1px
			background-color: #ebebeb
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			z-index: -1
