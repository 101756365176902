.product-main-detail
	padding-top: 30px
	.title-page
		font-weight: 500
		margin-bottom: 22px
	.btn-center
		margin-top: 29px
	.wrap-810
		max-width: 810px
		margin: 0 auto
		p
			text-align: center
		table
			margin-top: 25px
			width: 100%
			border-top: 2px solid $main
			tr
				td
					padding: 14px 0px
					border-bottom: 1px solid $line
					&:first-child
						background-color: $gray
						color: $black
						font-size: 14px
						font-weight: 700
						padding-left: 30px
						width: 230px
						@media (max-width: 575.98px)
							width: 50%
							padding-left: 15px
					&:last-child
						padding-left: 7px
						color: $black
						font-size: 14px
						font-weight: 400
						@media (max-width: 575.98px)
							width: 50%
							padding-left: 15px
	.product-content-detail
		margin-bottom: 30px
	.wrap-gallery-image
		margin-bottom: r(100px)
		@media (max-width: 1023.98px)
			margin-bottom: r(40px)
		.row
			margin-left: -5px
			margin-right: -5px
			[class*='col']
				padding-left: 5px
				padding-right: 5px
		.item-gallery
			margin-bottom: 10px
			&.small
				a
					+img-ratio(232/403)
			a
				+img-ratio(350/610)


.wrap-main-3d
	background-color: #f8f8f8
	padding-top: r(30px)
	padding-bottom: r(80px)
	@media (max-width: 1023.98px)
		padding-bottom: r(30px)
	.title-image-3d
		color: $black
		font-size: r(30px)
		font-weight: 500
		text-align: center
		margin-bottom: 30px
	.wrap-img-3d
		height: 0
		overflow: hidden
		padding-top: calc( 690 / 1230 * 100% )
		background-color: white
		position: relative
		margin-bottom: 30px
		.icon
			position: absolute
			top: 20px
			right: 20px
			z-index: 9
		.img-3d
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
.product-detail-infomation
	position: relative
	.file-download
		margin-top: r(20px)
		.icon
			display: flex
			align-items: center
			justify-content: center
			width: 40px
			height: 40px
			border-radius: 50%
			margin-left: 15px
			border: 1px solid $line
			transition: .3s all ease-in-out
			margin-left: r(20px)
			em
				transition: .3s all ease-in-out
			&:hover
				background-color: $main
				em
					color: white
	.container
		position: absolute
		top: 0
		left: 50%
		height: 100%
		transform: translateX(-50%)
	.row
		height: 100%
	.col-lg-6
		height: 100%
	.img
		img
			height: 100%
			width: 100%
			object-fit: cover
		@media (max-width: 1023.98px)
			height: 350px
	.box-info-product
		background-color: rgba(255, 255, 255, .9)
		height: 100%
		padding: 48px 19px 30px 38px
		@media (max-width: 1279.98px)
			padding-top: 20px
		.share-social
			display: flex
			align-items: center
			margin-top: 19px
			p
				color: $black
				font-size: 14px
				font-weight: 500
				line-height: 0.01px
			a
				display: flex
				align-items: center
				justify-content: center
				width: 40px
				height: 40px
				border-radius: 50%
				margin-left: 15px
				border: 1px solid $line
				transition: .3s all ease-in-out
				em
					transition: .3s all ease-in-out
				&:hover
					background-color: $main
					em
						color: white
		.content
			max-height: 400px
			padding-right: 40px
			overflow: auto
			@media (max-width: 1700px)
				max-height: 76%
			@media (max-width: 1279.98px)
				max-height: calc( 100% - 80px )
			&::-webkit-scrollbar
				width: 3
			&::-webkit-scrollbar-track
				background: #d1d1d1
			&::-webkit-scrollbar-thumb
				background: $main
			table
				margin-top: r(15px)
				tr
					td
						border: 1px solid #e0e0e0
						padding: 5px
		.title
			color: $black
			font-size: r(30px)
			font-weight: 500
			margin-bottom: 5px
		ul
			+type-none()
			li
				color: $black
				font-size: 15px
				font-weight: 400
				display: flex
				line-height: 2.4
				@media (max-width: 575.98px)
					line-height: 1.8
				&::before
					content: 'lens'
					color: #999999
					font-family: "Material Icons"
					font-size: 8px
					padding-top: 10px
					margin-right: 16px
.sec-product-main
	padding-top: r(68px)
	@media (max-width: 1023.98px)
		padding-top: r(30px)
	.title-main
		margin-bottom: 30px
.other-product-detail
	.product-title-main
		text-transform: uppercase
		margin-top: 15px
		display: block
#modal-popup-phone
	position: relative
	overflow: visible
	.icon-circle
		width: 	70px
		height: 70px
		background-color: $main
		border-radius: 50%
		position: absolute
		top: 0
		left: 50%
		transform: translateX(-50%) translateY(-50%)
		display: flex
		align-items: center
		justify-content: center
		em
			color: white
			font-size: r(38px)
	p
		color: $main
		text-align: center
		font-size: r(24px)
		font-weight: 600
	.wrap-content
		margin-top: 20px
	.fancybox-close-small
		background-color: $main
		color: white
		opacity: 1
		padding: 5px
.product-detail-page
	.fancybox-is-open .fancybox-bg
		opacity: .3 !important

