.wrap-form
	span
		&.fa-exclamation-triangle
			font-size: r(14px)
			color: red
			&::before
				margin-right: 5px
	.form-row
		display: flex
		margin: 0 -15px
		@media screen and ( max-width: 768px )
			flex-wrap: wrap
		.form-group
			flex: 0 0 50%
			padding: 0 15px
			@media screen and ( max-width: 768px )
				flex: 0 0 100%
			input[type="text"]
				width: 100%
span.fa-exclamation-triangle
	font-weight: 400!important
	font-style: italic
	color: red
	font-family: 'Philosopher', sans-serif!important
	font-size: .9rem
	&::before
		display: none!important
// form capcha
.frm-btn-reset
	display: none!important
.frm-captcha
	display: flex
	float: left
	width: calc(100% - 233px)
	// flex: 1
	padding-right: 20px
	@media screen and ( max-width: 1280px )
		width: calc(100% - 110px)
	@media screen and ( max-width: 1023.67px )
		float: none
		padding-right: 0
		flex: 1
		width: 100%
	@media screen and ( max-width: 767.67px )
		flex-direction: column
	.label
		display: none!important
	.frm-captcha-input
		order: 2
		flex: 1
		@media screen and ( max-width: 767.67px )
			order: 1
			margin-bottom: 20px
	.RadCaptcha_Default
		position: relative
		@media screen and ( max-width: 767.67px )
			flex: 0 0 100%
			order: 2

	#ctl00_mainContent_ctl02_captcha_ctl00
		position: absolute
		top: 120%
		color: red
	#ctl00_mainContent_ctl02_txtCaptcha
		width: 100%
		height: 40px
		margin-top: 0
		border: 1px solid #e1e1e1
		background-color: #fefefe
		@media screen and ( max-width: 767.67px )
			width: 100%

	#ctl00_mainContent_ctl02_captcha_ctl01
		display: flex
		align-items: center
		img
			width: 100%!important
			height: 40px!important
		.rcRefreshImage
			font-size: 0
			margin-left: 5px
			height: auto!important
			margin-right: 10px
			&::after
				display: none!important
			&::before
				content: "\e861"
				font-family: Linearicons-Free
				color: $text
				font-size: 30px
				font-weight: 400
.subscribefrm
	.fa-exclamation-triangle
		top: 110%!important
		right: 0!important
		width: auto!important
		text-indent: 0!important
		&::after
			display: none

